import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

interface MutationArguments {
  id: string;
}

function deleteLeadPhysician({ id }: MutationArguments) {
  return axiosInstance.delete(`/admin/invitation/${id}`);
}

export function useDeleteLeadPhysicianMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, MutationArguments, unknown>,
    "mutationFn"
  >,
) {
  return useMutation<unknown, unknown, MutationArguments>(
    deleteLeadPhysician,
    options,
  );
}
