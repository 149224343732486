import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

type UserRegistration = {
  email: string;
  registrationCode: string;
  name: string;
  surname: string;
  language: string;
};

function saveLeadPhysician(data: UserRegistration) {
  return axiosInstance.post("register/leadphysician", data, {
    headers: {
      Authorization: undefined,
    },
  });
}

export function useSaveLeadPhysicianMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, UserRegistration, unknown>,
    "mutationFn"
  >,
) {
  return useMutation<unknown, unknown, UserRegistration>(
    saveLeadPhysician,
    options,
  );
}
