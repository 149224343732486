import axios from "axios";
import keycloakInstance from "../Keycloak";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API + "/v1/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = keycloakInstance.token;
  config.headers = config.headers || {};
  // @ts-ignore
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
