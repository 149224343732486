import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

interface MutationArguments {
  avatar: FormData;
}

function mutationFn(args: MutationArguments) {
  return axiosInstance.post("account/avatar", args.avatar, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function useAddAvatarImgUploadMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, MutationArguments>,
    "mutationFn"
  >,
) {
  return useMutation(mutationFn, options);
}
