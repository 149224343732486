import { useEffect } from "react";
import ContactPanel from "../../components/ContactPanel";
import { pushCustomPageViewEventToCountly } from "../../services/countly.service";
import { useLocation } from "react-router-dom";
const Contact = () => {
  const location = useLocation();
  
  // keep this useEffect as is, important for countly statistics
  useEffect(() => {
    const startTime = new Date();
    return pushCustomPageViewEventToCountly(startTime, location.pathname);
  }, [location]);
  return <ContactPanel />;
};

export default Contact;
