import { Link } from "react-router-dom";
import styled from "styled-components";

const PromptStep = () => {
  return (
    <Container>
      <p>
        Before proceeding, please be sure to review the
        <Link rel="noreferrer" target="_blank" to="/terms-of-use">
          {" "}
          Terms of Service{" "}
        </Link>
        regarding deletions.
      </p>
      <p>
        This action CANNOT be undone. Please be certain about your decision.
      </p>
      <p>
        Deleting this account will delete all of its data and access to ONE4
        services after 14 days. You cannot undo this action.
      </p>
    </Container>
  );
};

export default PromptStep;

const Container = styled.div`
  margin-bottom: 14px;
  p {
    color: #4e6276;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }
  a {
    color: #5080f4;
  }
`;
