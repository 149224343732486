import { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import ProfileSidebar from "../../components/Profile/Sidebar";
import {
  ContentLayout,
  ProfileContainer,
} from "../../components/Profile/styled";
import { ITab } from "../../types";
import { useLocation } from "react-router-dom";
import { pushCustomPageViewEventToCountly } from "../../services/countly.service";
import ProfileMobileVersion from "../../components/Profile/ProfileMobileVersion";
import NotificationsTab from "../../components/Profile/Tabs/NotificationsTab";
import ProfileTab from "../../components/Profile/Tabs/ProfileTab";
import ExpertSettings from "../../components/Profile/ExpertSettings";

const Profile = () => {
  const [tab, setTab] = useState<ITab>("profile");
  const location = useLocation();

  // keep this useEffect as is, important for countly statistics
  useEffect(() => {
    const startTime = new Date();
    return pushCustomPageViewEventToCountly(startTime, location.pathname);
  }, [location]);

  return (
    <Layout>
      <ProfileContainer>
        <ProfileSidebar tab={tab} setTab={setTab} />
        <ContentLayout>
          {tab === "profile" ? <ProfileTab /> : <NotificationsTab />}
          <ExpertSettings radius="4px" />
        </ContentLayout>
      </ProfileContainer>
      <ProfileMobileVersion />
    </Layout>
  );
};

export default Profile;
