import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import styled from "styled-components";
import ListSkeleton from "../../../../components/ListSkeleton";
import { useGetAllPractitionersQuery } from "../../../../hooks/queries";
import DateFormatter from "../../../../utils/DateFormater";
import { useDeactivateSectionContext } from "../../ExpertSettings";
import ModalV3 from "../../../Modalv3";
import { useState } from "react";
import Button from "../../../Button";
import { Colors } from "../../../../Contstants";

const ChooseLeadPhysicianStep = () => {
  const { setInheritorId, inheritorId, setDisableStatus } =
    useDeactivateSectionContext();
  const [isOpen, setIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({
    name: "",
    surname: "",
    id: "",
  });

  const { data, isLoading } = useGetAllPractitionersQuery({
    refetchOnWindowFocus: false,
  });

  const verifiedPractitioners =
    data?.data.filter((practitioner) => practitioner.active) || [];

  if (isLoading) {
    return <ListSkeleton listsToRender={3} />;
  }

  return (
    <>
      <ResponsiveDiv>
        <TableContainer
          sx={{
            boxShadow: "none",
            borderRadius: "10px",
            border: "1px solid #e0e7ed",
          }}
          component={"div"}
        >
          <TableContent size="small">
            <TableHead>
              <TableRow>
                <TableColumn align="left">Name</TableColumn>
                <TableColumn align="left">Profession</TableColumn>
                <TableColumn align="center">Email</TableColumn>
                <TableColumn align="left">Onboarding date</TableColumn>
                <TableColumn align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {verifiedPractitioners.map((row) => {
                const userName = row.name + " " + row.surname;

                return (
                  <Row key={row.id}>
                    <TableLine align="left">{userName}</TableLine>
                    <TableLine align="left">{row.profession}</TableLine>
                    <TableLine align="left">{row.email}</TableLine>
                    <TableLine align="left">
                      {DateFormatter(row.createdAt)}
                    </TableLine>
                    <TableLine align="left">
                      <Button
                        onClick={() => {
                          setCurrentRow(row);
                          setIsOpen(true);
                        }}
                        disabled={row.id === inheritorId}
                        title={
                          row.id === inheritorId ? "Chosen" : "Choose inheritor"
                        }
                        small
                      />
                    </TableLine>
                  </Row>
                );
              })}
              {currentRow && (
                <ModalV3
                  onClose={() => setIsOpen(false)}
                  isVisible={isOpen}
                  description={`Do you want to make ${
                    currentRow.name + " " + currentRow.surname
                  } as a new Lead Physician?`}
                  isErrorCircleVisible={false}
                  closeButtonIsHidden
                >
                  <ButtonsSection>
                    <CancelButton onClick={() => setIsOpen(false)}>
                      Close
                    </CancelButton>
                    <ConfirmButton
                      onClick={() => {
                        setDisableStatus(false);
                        setInheritorId(currentRow.id);
                        setIsOpen(false);
                      }}
                    >
                      Yes
                    </ConfirmButton>
                  </ButtonsSection>
                </ModalV3>
              )}
            </TableBody>
          </TableContent>
        </TableContainer>
      </ResponsiveDiv>
      {/* {data.map((row) => {
          return (
            <CentersMobileView
              key={row.id}
              title={row.title}
              client={row.clientId}
              postCode={row.postCode}
              city={row.city}
              country={row.country}
              onEdit={() => setEdit(row)}
              onDelete={() => onDelete(row.id)}
            />
          );
        })} */}
    </>
  );
};

export default ChooseLeadPhysicianStep;

const ResponsiveDiv = styled.div`
  max-width: 400px;
  @media (width >= 768px) {
    display: block;
    margin-bottom: 20px;
    max-width: none;
  }
`;

const TableContent = styled(Table)`
  * {
    border: none;
  }
`;

const TableColumn = styled(TableCell)`
  color: #4e6276 !important;
  font-size: 14px !important;
  background-color: #f9f9f9;
  padding: 16px !important;
  font-family: "Roboto";
  font-weight: 400 !important;
`;

const Row = styled(TableRow)`
  &:hover {
    background-color: #f8f8f8;
  }
`;

const TableLine = styled(TableCell)`
  font-size: 14px !important;
  color: #142a5e !important;
  font-weight: 600 !important;
  padding: 16px !important;
`;

const ButtonsSection = styled.div`
  display: flex;
  gap: 10px;
`;

const CancelButton = styled.button`
  background-color: #ffffff;
  display: flex;
  width: 136px;
  height: 45px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${Colors.newBlue};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #d0d5dd;
`;

const ConfirmButton = styled.button`
  background-color: #ee505a;
  display: flex;
  width: 136px;
  height: 45px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #ffffff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  border: none;
`;

// const ResponsiveFrameOfMobile = styled.div`
//   border-radius: 20px;
//   background: #fff;
//   box-shadow: 0px 4px 16px 0px rgba(175, 175, 175, 0.25);
//   margin: 10px;
//   min-height: 310px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 600;
//   line-height: 24px;
//   letter-spacing: 0px;
//   text-align: left;
//   color: #051123;
//   @media (min-width: 768px) {
//     display: none;
//   }
// `;
