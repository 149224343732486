import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import EducateImg from "../../assets/images/mainLogo.svg";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
import Checkbox from "../Checkbox";

const ActivePanel = () => {
  const [firstCheckbox, setFirstCheckbox] = useState<boolean>(false);
  const [secondCheckbox, setSecondCheckbox] = useState<boolean>(false);

  const navigate = useNavigate();

  const continueButtonHandler = async () => {
    try {
      await axiosInstance.post("account/consent/one4educate");
      navigate("/");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <MainContainer>
      <HeaderImg src={EducateImg} alt="educate" />
      <MainText>
        <div>
          <p>
            Please check the boxes that you agree to our{" "}
            <strong>Terms of Service</strong> and the{" "}
            <strong>Privacy Policy</strong>.
          </p>
          <p>
            Please note that if you do not agree, you will not be able to
            proceed.
          </p>
        </div>
      </MainText>
      <MiddleDiv>
        <CheckboxContainer>
          <Checkbox
            label={
              <p>
                I agree to the{" "}
                <Link rel="noreferrer" target="_blank" to="/terms-of-use">
                  Terms of Use
                </Link>
              </p>
            }
            onChange={(_, checked) => setFirstCheckbox(checked)}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            label={
              <p>
                I agree to the{" "}
                <Link rel="noreferrer" target="_blank" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </p>
            }
            onChange={(_, checked) => setSecondCheckbox(checked)}
          />
        </CheckboxContainer>
      </MiddleDiv>
      <ContinueButton
        onClick={continueButtonHandler}
        style={{ opacity: `${firstCheckbox && secondCheckbox ? "1" : "0.2"}` }}
        type="button"
      >
        Continue
      </ContinueButton>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeaderImg = styled.img`
  width: 204px;
  height: 42px;
  margin: 70px 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 13px;
  p {
    color: #4e6276;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  a {
    color: #5080f4;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const MiddleDiv = styled.div``;

const MainText = styled.div`
  display: flex;
  gap: 12px;
  p {
    color: #4e6276;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span {
    color: #5080f4;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
`;

const ContinueButton = styled.button`
  width: 423px;
  height: 57px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #142a5e;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 123px;
  border: none;
  cursor: pointer;
`;

export default ActivePanel;
