import { useRef, MouseEvent } from "react";
import { styled } from "styled-components";

const SuccessModal = (props: { setDisableStatus(status: boolean): void }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const MainContainerHandler = (e: MouseEvent<HTMLElement>) => {
    if (containerRef.current === e.target) {
      props.setDisableStatus(false);
    }
  };
  const cancelButtonHandler = () => {
    props.setDisableStatus(false);
  };
  return (
    <MainContainer ref={containerRef} onClick={(e) => MainContainerHandler(e)}>
      <ModalSection>
        <Container>
          <h3>Verify Your New Email!</h3>

          <p>
            We have send now a verification email to you. Once you verified the
            email by clicking on the verify link
          </p>
          <p>
            inside, your new email will be activated. Until then, the old email
            remains active.
          </p>
        </Container>
        <CancelButton onClick={cancelButtonHandler}>Close</CancelButton>
      </ModalSection>
    </MainContainer>
  );
};

export default SuccessModal;

const Container = styled.div`
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  p {
    color: #1a2127;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
  }
  h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    color: #3a9cad;
    align-self: center;
  }
`;

const MainContainer = styled.div`
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalSection = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 20px 0px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
`;

const CancelButton = styled.button`
  background-color: #ffffff;
  display: flex;
  width: 136px;
  height: 45px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #3a9cad;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #d0d5dd;
`;
