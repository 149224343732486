import { forwardRef } from "react";
import styles from "./styles.module.css";

interface Props extends React.ComponentProps<"input"> {
  icon?: React.ReactNode;
}

const RegistrationInput = forwardRef<HTMLInputElement, Props>(
  ({ className = "", icon, ...props }, ref) => {
    return (
      <div className={styles.container}>
        {icon ? <div className={styles.iconContainer}>{icon}</div> : null}
        <input
          ref={ref}
          {...props}
          className={`${styles.input} ${className}`}
        />
      </div>
    );
  }
);

export default RegistrationInput;
