import { z } from "zod";
import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { userSchema } from "./utils";
import { UserRole, UserScope } from "../../types/roles";

const schema = z.object({
  data: z.array(userSchema),
  _links: z.object({
    next: z.string().nullable().optional(),
    self: z.string().nullable().optional(),
  }),
});

type Response = z.infer<typeof schema>;

export function useGetUsersQuery(
  scope: UserScope,
  role: UserRole,
  options?: UseQueryOptions<Response>,
) {
  return useQuery<Response>(
    `${scope}-${role}s`,
    async () => {
      const response = await axiosInstance.get(`client/${scope}/${role}`);
      return schema.parse(response.data);
    },
    options,
  );
}
