export const ROLES = [
  "SUPER_ADMIN",
  "EDUCATE_LOCAL_MEMBER",
  "EDUCATE_TEAM_LEAD",
  "EDUCATE_PHYSICIAN",
  "EDUCATE_NURSE",
  "EDUCATE_PHARMACIST",
  "EDUCATE_PRACTITIONER",
] as const;

export type Role = (typeof ROLES)[number];

export const PRACTITIONER_ROLES = [
  "EDUCATE_PHYSICIAN",
  "EDUCATE_NURSE",
  "EDUCATE_PHARMACIST",
] as const;

export type PractitionerRole = (typeof PRACTITIONER_ROLES)[number];

export const ROLES_LABELS: Readonly<Record<Role, string>> = {
  SUPER_ADMIN: "Super Admin",
  EDUCATE_LOCAL_MEMBER: "Local Member",
  EDUCATE_TEAM_LEAD: "Lead Physician",
  EDUCATE_PHYSICIAN: "Physician",
  EDUCATE_NURSE: "Nurse",
  EDUCATE_PHARMACIST: "Pharmacist",
  EDUCATE_PRACTITIONER: "Practitioner",
};
