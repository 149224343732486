import Layout from "../../components/Layout";
import BackButtonWithText from "../../components/BackButtonWithText";
import { useLocation } from "react-router-dom";
import "./styles.css";

import {
  getBounceRates,
  getPageViewsAndTimeSpent,
  getTotalUsersCount,
  pushCustomPageViewEventToCountly,
} from "../../services/countly.service";
import { useEffect, useState } from "react";
import FeedbacksCountWidget from "../../components/Countly/FeedbacksCountWidget";
import PageViewsWidget, {
  PageData,
} from "../../components/Countly/PageViewsWidget";

import SessionsInfoWidget from "../../components/Countly/SessionsInfoWidget";
import BounceRateWidget from "../../components/Countly/BounceRateWidget";
import AcquisitionWidget from "../../components/Countly/AcquisitionWidget";
import UserStatsWidget from "../../components/Countly/UserStatsWidget";
import MultiBarChartLayout from "../../components/Countly/MultiBarChartLayout";
import { Typography } from "@mui/material";
import { styled } from "styled-components";

const textConstants = {
  pageTitle: "LMS Analytics",
  backButtonText: "Dashboard",
  totalUsers: "Total users: ",
  totalUsersLoading: "Loading total users count...",
};

const CountlyAnalytics = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);
  const [pages, setPages] = useState<{ [key: string]: PageData }>({});
  const [bounceRateData, setBounceRateData] = useState<any>();
  const location = useLocation();

  const fetchTotalUsers = async () => {
    return await getTotalUsersCount();
  };

  const fetchPageViewsAndTimeSpent = async () => {
    return await getPageViewsAndTimeSpent();
  };

  const fetchBounceRateData = async () => {
    return await getBounceRates();
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const totalUsersResponse = await fetchTotalUsers();
        if (totalUsersResponse.totalUsers) {
          setTotalUsersCount(totalUsersResponse.totalUsers);
        }

        const pageViewsResponse = await fetchPageViewsAndTimeSpent();
        if (pageViewsResponse) {
          setPages(pageViewsResponse);
        }

        const bounceRatesResponse = await fetchBounceRateData();
        if (bounceRatesResponse) {
          setBounceRateData(bounceRatesResponse);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    setIsLoading(false);
  }, []);

  // keep this useEffect as is, important for countly statistics
  useEffect(() => {
    const startTime = new Date();
    return pushCustomPageViewEventToCountly(startTime, location.pathname);
  }, [location]);

  return (
    <Layout>
      <LocalAdminCnt>
        {!isLoading && (
          <div className="analytics-container">
            <BackButtonWithText
              navigateTo="/"
              text={textConstants.backButtonText}
            />
            <div className="analytics-header">
              <Typography
                variant="h4"
                align="center"
                style={{ margin: "20px 0", color: "#1976D2" }}
              >
                {textConstants.pageTitle}
              </Typography>
            </div>
            <UserStatsWidget totalUsers={totalUsersCount} />
            <br />
            <FeedbacksCountWidget />
            <br />
            <MultiBarChartLayout />
            <br />
            {bounceRateData && <BounceRateWidget records={bounceRateData} />}
            <br />
            <PageViewsWidget data={pages} />
            <br />
            <SessionsInfoWidget />
            <br />
            <AcquisitionWidget />
          </div>
        )}
      </LocalAdminCnt>
    </Layout>
  );
};

export default CountlyAnalytics;

const LocalAdminCnt = styled.div`
  min-height: calc(100vh - 210px);
  img {
    width: 100%;
    height: 100%;
  }

  .dashboard {
    img {
    }
  }

  & .flex {
    display: flex;
    align-items: center;
  }
  .eduModules {
    .img {
    }
  }

  .flexCol {
    display: flex;
    flex-direction: column;
    img {
      cursor: pointer;
    }
  }
`;
