import { useEffect, useState } from "react";
import { styled } from "styled-components";
import Checkbox from "../../../Checkbox";

const CheckBoxStep = (props: { setDisableStatus(status: boolean): void }) => {
  const [data, setData] = useState([
    {
      checked: false,
      text: "My account information will be lost",
    },
    {
      checked: false,
      text: "I won’t be able to undo this action",
    },
    {
      checked: false,
      text: "I won’t have access to ONE4 services",
    },
    {
      checked: false,
      text: "My profile and data will be permanently deleted",
    },
  ]);

  useEffect(() => {
    if (data.every((item) => item.checked)) {
      props.setDisableStatus(false);
    } else {
      props.setDisableStatus(true);
    }
  }, [data, props]);

  return (
    <Container>
      <h2>
        If you still want to deactivate your account, make sure to agree on the
        results it will have.
      </h2>
      {data.map((item, index) => {
        return (
          <Checkbox
            key={index}
            onChange={(_, checked) => {
              let newData = data.slice();
              newData[index].checked = checked;
              setData(newData);
            }}
            label={<span>{item.text}</span>}
          />
        );
      })}
    </Container>
  );
};

export default CheckBoxStep;

const Container = styled.div`
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  color: #1a2127;
  h2 {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }
  p {
    color: #1a2127;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }
  span {
    color: #4e6276;
  }
`;
