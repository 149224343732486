import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

interface MutationArguments {
  name: string;
  surname: string;
  language: string;
}

function mutationFn({ name, surname, language }: MutationArguments) {
  return axiosInstance.patch("/account", {
    name,
    surname,
    language,
  });
}

export function useModifyUserMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, MutationArguments>,
    "mutationFn"
  >,
) {
  return useMutation(mutationFn, options);
}
