import { useState } from "react";
import Button from "../Button";
import { RegistrationFormContainer } from "./styled";
import RegistrationHeadline from "./RegistrationHeadline";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { FormInput } from "../../form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { RegistrationDto, RegistrationFormSchema } from "./utils";
import styles from "./styles.module.css";
import { styled } from "styled-components";
import ExplorationMarkImg from "../../assets/images/explorationMark.png";
import { useCheckEmailMutation } from "../../hooks/mutations/useCheckEmailMutation";

interface Props {
  setStep: (index: 0 | 1 | 2 | 3) => void;
  setFormData: (data: RegistrationDto) => void;
}

const RegistrationForm = ({ setStep, setFormData }: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<RegistrationDto>({
    resolver: zodResolver(RegistrationFormSchema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const { mutate } = useCheckEmailMutation({});
  const { t } = useTranslation();

  const [errorText, setErrorText] = useState("");
  const isButtonDisabled =
    !watch("name") ||
    !watch("surname") ||
    !/^(.+)@(.+)\.(.+)$/.test(watch("email"));

  const onValid = async (data: RegistrationDto) => {
    mutate(
      { email: data.email },
      {
        onSuccess: (response) => {
          setFormData({ ...response.data, ...data });
          setStep(2);
        },
        onError: (error) => {
          if (error instanceof axios.AxiosError) {
            if (error.response?.data) {
              setErrorText(error.response.data);
            } else {
              setErrorText("Something went wrong");
            }
          }
        },
      },
    );
  };

  return (
    <RegistrationFormContainer $position="center">
      <RegistrationHeadline />
      <div className={`${styles.nameContainer} full-width`}>
        <div className={`${styles.countryInputsContainer} full-width`}>
          <FormInput
            control={control}
            hasError={Boolean(errors.name)}
            label={t("registration.first_name")}
            name="name"
            required
            defaultValue=""
          />
        </div>
        <div className={`${styles.countryInputsContainer} full-width`}>
          <FormInput
            control={control}
            hasError={Boolean(errors.surname)}
            label={t("registration.last_name")}
            name="surname"
            required
            defaultValue=""
          />
        </div>
      </div>
      <div className="full-width">
        <FormInput
          control={control}
          hasError={Boolean(errors.email)}
          label={t("registration.email")}
          name="email"
          type="email"
          required
          defaultValue=""
        />
        <MakeSureText>
          <Circle>
            <ExplorationMark src={ExplorationMarkImg} alt="explorationMark" />
          </Circle>
          please make sure to use your official center email address
        </MakeSureText>
        {errorText ? <ErrorText>{errorText}</ErrorText> : null}
      </div>
      <div className={`submit ${isSubmitting ? "cursorChange" : ""}`}>
        <Button
          isLoading={isSubmitting}
          disabled={isButtonDisabled}
          fullWidth
          title={isSubmitting ? " " : "Next"}
          onClick={handleSubmit(onValid, console.log)}
        />
      </div>
    </RegistrationFormContainer>
  );
};

export default RegistrationForm;

const MakeSureText = styled.span`
  color: #5e95e6;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ExplorationMark = styled.img`
  width: 14px;
  height: 14px;
`;

const Circle = styled.div`
  border-radius: 50%;
  border: 2px solid #5e95e6;
  width: 13.333px;
  height: 13.333px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.span`
  color: #e65e6e;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
