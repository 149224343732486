import React from "react";
import { Link } from "react-router-dom";

function BackButtonWithText({
  navigateTo,
  text,
}: {
  navigateTo: string;
  text: string;
}) {
  return (
    <React.Fragment>
      <Link to={`${navigateTo}`}>
        <div className="flex">
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#5080f4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 8L8 12L12 16"
                stroke="#5080f4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 12H8"
                stroke="#5080f4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div style={{ color: "#5080f4", padding: "0px 3px 3px 3px" }}>
            {text}
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
}

export default BackButtonWithText;
