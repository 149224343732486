import { z } from "zod";
import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { userSchema } from "./utils";

const schema = z.object({
  data: z.array(userSchema),
  _links: z.object({
    next: z.string().nullable().optional(),
    self: z.string().nullable().optional(),
  }),
});

type Response = z.infer<typeof schema>;

export type Practitioner = Response["data"][number];
export type Admin = Response["data"][number];

async function getAllPractitioners() {
  const data = await axiosInstance
    .get(`practitioner?limit=10&offset=0`)
    .then((res) => res.data);
  return schema.parse(data);
}

export function useGetAllPractitionersQuery(
  options?: UseQueryOptions<Response>,
) {
  return useQuery<Response>("practitioners", getAllPractitioners, options);
}
