import styles from "./styles.module.css";
import RegistrationCodePrompt from "./components/RegistrationCodePrompt";
import RegistrationForm from "../../components/Registration/RegistrationForm";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { pushCustomPageViewEventToCountly } from "../../services/countly.service";
import { styled } from "styled-components";
import LastRegistrationForm from "../../components/Registration/LastRegistrationForm";
import SuccessMessage from "../../components/Registration/SuccessMessage";
import { RegistrationDto } from "../../components/Registration/utils";

const one4EducateImg =
  process.env.REACT_APP_AWS_ONE4_BASE_URL + "one4educate.png";

const registrationMockup =
  process.env.REACT_APP_AWS_ONE4_BASE_URL + "One4EducateRegistrationMockup.svg";

const Registration = () => {
  const [step, setStep] = useState<0 | 1 | 2 | 3>(0);
  const [formData, setFormData] = useState<RegistrationDto>(() => ({
    city: "",
    country: "",
    email: "",
    name: "",
    surname: "",
    title: "",
  }));

  const location = useLocation();

  const onActivate = () => {
    setStep(1);
  };

  // keep this useEffect as is, important for countly statistics
  useEffect(() => {
    const startTime = new Date();
    return pushCustomPageViewEventToCountly(startTime, location.pathname);
  }, [location]);

  return (
    <main className={styles.containerWrapper}>
      <header className={styles.pageHeader}>
        <Image src={one4EducateImg} alt="Logo" className={styles.headerLogo} />
      </header>
      <section className={styles.container}>
        <div className={styles.welcomeContainer}>
          <PlaceHolderDiv>
            <div className={styles.imagesContainer}>
              <img
                src={registrationMockup}
                alt="Shows computer with One4Educate screenshot"
                className={styles.registrationDisplay}
              />
            </div>
          </PlaceHolderDiv>
          <div>
            <h1 className={styles.title}>
              Welcome to
              <SecondTitle>ONE4</SecondTitle>
              <ThirdTitle>EDUCATE</ThirdTitle>
            </h1>
            <h2 className={styles.subtitle}>
              Your eLearning Platform for the next generation gene therapy for
              DMD
            </h2>
          </div>
        </div>
        {/* <img alt="registration" src={registrationImg} /> */}
        <div className={styles.content}>
          {step === 0 ? (
            <RegistrationCodePrompt onActivate={onActivate} />
          ) : step === 1 ? (
            <RegistrationForm setFormData={setFormData} setStep={setStep} />
          ) : step === 2 ? (
            <LastRegistrationForm formData={formData} setStep={setStep} />
          ) : (
            <SuccessMessage />
          )}
        </div>
      </section>
    </main>
  );
};

export default Registration;

const VectorImg = styled.img`
  width: 30px;
  height: 30px;
`;
const SecondTitle = styled.span`
  font-family: Inter;
  font-size: 40px;
  color: white;
  font-weight: 600;
  margin: 0;
  margin-bottom: 16px;
  letter-spacing: 2px;
  padding-left: 5px;
`;
const ThirdTitle = styled.span`
  font-family: Inter;
  font-size: 40px;
  color: white;
  font-weight: 600;
  margin: 0;
  margin-bottom: 16px;
  letter-spacing: 2px;
  padding-left: 5px;
`;

const Image = styled.img`
  /* display: none; */
  /* @media (width > 768px) {
    display: block;
  } */
`;

const PlaceHolderDiv = styled.div`
  @media (width > 1200px) {
    width: 680px;
    height: 480px;
  }
`;
