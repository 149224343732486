import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

type Argument = {
  leaveFeedbackText: string;
  inheritorId: string;
};

function deleteAccount(data: Argument) {
  return axiosInstance.delete(`/account`, {
    data: { feedback: data.leaveFeedbackText, inheritorId: data.inheritorId },
  });
}

export function useDeleteAccountMutation(
  options?: Omit<UseMutationOptions<unknown, unknown, Argument>, "mutationFn">,
) {
  return useMutation(deleteAccount, options);
}
