import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import { pushCustomPageViewEventToCountly } from "../../services/countly.service";
import TermsText from "../../components/TermsOfUse";

const TermsOfUse = () => {
  const location = useLocation();

  // keep this useEffect as is, important for countly statistics
  useEffect(() => {
    const startTime = new Date();
    return pushCustomPageViewEventToCountly(startTime, location.pathname);
  }, [location]);

  return (
    <Layout>
      <>
        <TermsText />
      </>
    </Layout>
  );
};

export default TermsOfUse;
