import { styled } from "styled-components";
import Input from "../../Input";
import Button from "../../Button";
import SuccessModal from "./SuccessModal";
import { useState } from "react";
import { useChangeEmailMutation } from "../../../hooks/mutations/useChangeEmailMutation";
import { AxiosError } from "axios";
import ModalV3 from "../../Modalv3";

const ChangeEmail = (props: { isEmailSectionOpen: boolean }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isWarningTextVisible, setIsWarningTextVisible] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [email, setEmail] = useState("");
  const [confirmationEmail, setConfirmationEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  const { mutate, isLoading } = useChangeEmailMutation({
    onSuccess: () => {
      setIsSuccessModalOpen(true);
      setNewEmail(email);
      setIsWarningTextVisible(true);
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data);
        setIsErrorModalVisible(true);
      }
    },
  });

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const inputHandler = (value: string) => {
    setEmail(value);
    setIsWarningTextVisible(false);
  };

  const confirmationInputHandler = (value: string) => {
    setConfirmationEmail(value);
    setIsWarningTextVisible(false);
  };

  const submitHandler = () => {
    mutate({
      newEmail: email,
    });
  };

  return (
    <MainSection $isOpen={props.isEmailSectionOpen}>
      <InputAndButtonSection>
        <LabelAndInputSection>
          <div>
            <label>New email</label>
            <Input
              onChange={inputHandler}
              name="email"
              placeholder="example@email.com"
            />
          </div>
          <div>
            <label>Confirm new email</label>
            <Input
              onChange={confirmationInputHandler}
              name="email"
              placeholder="example@email.com"
              disablePaste
            />
          </div>
        </LabelAndInputSection>
        <Button
          title="Submit"
          onClick={submitHandler}
          width="180px"
          green
          tight
          disabled={
            !emailRegex.test(email) ||
            email === newEmail ||
            confirmationEmail !== email
          }
          isLoading={isLoading}
        />
      </InputAndButtonSection>
      {isWarningTextVisible ? (
        <WarningText>
          *Verification mail was sent to <span>{email}</span>, please make sure
          you verify this email address. Until then, the old email remains
          active.
        </WarningText>
      ) : null}

      {isSuccessModalOpen ? (
        <SuccessModal setDisableStatus={setIsSuccessModalOpen} />
      ) : null}
      <ModalV3
        isErrorCircleVisible={true}
        isVisible={isErrorModalVisible}
        onClose={() => setIsErrorModalVisible(false)}
        description={errorMessage}
      ></ModalV3>
    </MainSection>
  );
};

export default ChangeEmail;

const MainSection = styled.div<{ $isOpen: boolean }>`
  margin-left: 10px;
  flex-direction: column;
  transition: 0.5s ease-in-out;
  display: ${(props) => (props.$isOpen ? "flex" : "none")};
  label {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
    margin-bottom: 4px;
  }
  input {
    min-width: 280px;
  }
  button {
    @media (width >= 760px) {
      margin-bottom: 7px;
    }
  }
`;

const InputAndButtonSection = styled.div`
  @media (width >= 768px) {
    display: flex;
  }
`;

const WarningText = styled.p`
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  color: #013567;
  max-width: 635px;
  span {
    font-weight: 700;
  }
`;

const LabelAndInputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
