import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Spinner from "../../components/Spinner";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Wrapper>
      <Spinner isSpinnerCentered />
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;
