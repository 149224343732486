import { LazyExoticComponent, Suspense, lazy } from "react";
import { Role } from "../../constants";
import Spinner from "../../components/Spinner";
import { useUser } from "../../hooks/queries";

const renderViews: Record<Role, LazyExoticComponent<() => JSX.Element>> = {
  EDUCATE_TEAM_LEAD: lazy(() => import("./views/LeadPhysicianView")),
  EDUCATE_LOCAL_MEMBER: lazy(() => import("./views/LocalMemberView")),
  SUPER_ADMIN: lazy(() => import("./views/SuperAdminView")),
  EDUCATE_PHYSICIAN: lazy(() => import("./views/PractitionerView")),
  EDUCATE_NURSE: lazy(() => import("./views/PractitionerView")),
  EDUCATE_PHARMACIST: lazy(() => import("./views/PractitionerView")),
  EDUCATE_PRACTITIONER: lazy(() => import("./views/PractitionerView")),
};

const Dashboard = () => {
  const userRolesArr = useUser().user?.roles;
  const userRole = userRolesArr?.includes("EDUCATE_TEAM_LEAD")
    ? "EDUCATE_TEAM_LEAD"
    : userRolesArr?.[0];

  if (!userRole) {
    return <Spinner isSpinnerCentered={true} />;
  }

  const RoleView = renderViews[userRole];

  return (
    <Suspense>
      <RoleView />
    </Suspense>
  );
};

export default Dashboard;
