import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    id: z.string(),
    centerId: z.string(),
    center: z.string(),
    email: z.string(),
    createdAt: z.string(),
    expiresAt: z.string(),
    usedAt: z.string().nullable(),
    createdBy: z.string(),
  }),
);

type Response = z.infer<typeof schema>;

async function getLeadPhysiciansList() {
  const { data } = await axiosInstance.get("admin/invitations");
  return schema.parse(data);
}

export function useGetLeadPhysiciansListQuery(
  options?: UseQueryOptions<Response>,
) {
  return useQuery<Response>("invitations", getLeadPhysiciansList, options);
}
