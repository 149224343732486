import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

interface MutationArguments {
  newEmail: string;
}

function changeEmail(body: MutationArguments) {
  return axiosInstance.post("account/change-email", body);
}

export function useChangeEmailMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, MutationArguments>,
    "mutationFn"
  >,
) {
  return useMutation(changeEmail, options);
}
