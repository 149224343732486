import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { getAcquisitionData } from "../../../services/countly.service";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AcquisitionWidget: React.FC = () => {
  const [acquisitionData, setAcquisitionData] =
    useState<Array<{ source: string; totalVisits: number }>>();

  const fetchData = async () => {
    let data = await getAcquisitionData();
    if (data) {
      const formattedData = data.map(
        (record: { source: string; totalVisits: number }) => ({
          source: record.source.replaceAll("&#46;", "."),
          totalVisits: record.totalVisits,
        })
      );
      setAcquisitionData(formattedData);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <h5>Acquisition - where users come to our app from</h5>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper} className="acquisition-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Source</TableCell>
                <TableCell>Total Visits</TableCell>
              </TableRow>
            </TableHead>
            {acquisitionData && acquisitionData.length ? (
              <TableBody>
                {acquisitionData.map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{row.source}</TableCell>
                    <TableCell>{row.totalVisits}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Loading...</TableCell>
                </TableRow>
              </TableHead>
            )}
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default AcquisitionWidget;
