import { styled } from "styled-components";
import { MouseEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteAccountMutation } from "../../../hooks/mutations/useDeleteAccountMutation";
import PromptStep from "./DeactiavtionSteps/PromptStep";
import CheckBoxStep from "./DeactiavtionSteps/CheckboxStep";
import FeedbackStep from "./DeactiavtionSteps/FeedbackStep";
import SuccessText from "./DeactiavtionSteps/SuccessText";
import Spinner from "../../Spinner";
import XMark from "../../../assets/images/close.png";
import ChooseLeadPhysicianStep from "./DeactiavtionSteps/ChooseLeadPhysicianstep";
import { useDeactivateSectionContext } from "../ExpertSettings";

const DeactivateAccount = () => {
  const {
    setCurrentStep,
    currentStep,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    disableStatus,
    setDisableStatus,
    inheritorId,
    isUserTeamLead,
    setInheritorId,
    activePractitionersListCount,
  } = useDeactivateSectionContext();

  const [leaveFeedbackText, setLeaveFeedbackText] = useState("");
  const { mutate, isLoading } = useDeleteAccountMutation({
    onSuccess: () => {
      setCurrentStep(4);
    },
  });

  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  const DeactivateButtonHandler = () => {
    if (currentStep === 0) {
      if (!disableStatus) {
        setCurrentStep(1);
      }
    } else if (currentStep === 1) {
      setCurrentStep(2);
      setDisableStatus(true);
    } else if (currentStep === 2) {
      if (!disableStatus) {
        setDisableStatus(true);
        setCurrentStep(3);
      }
    } else {
      mutate({
        leaveFeedbackText,
        inheritorId,
      });
    }
  };

  const MainContainerHandler = (e: MouseEvent<HTMLElement>) => {
    if (containerRef.current === e.target) {
      setIsDeleteModalVisible(false);
      setDisableStatus(false);
      setInheritorId("");
    }
  };

  const backButtonHandler = () => {
    if (isUserTeamLead && activePractitionersListCount > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(Math.max(1, currentStep - 1));
    }
    setDisableStatus(false);
  };

  const xMarkHandler = () => {
    setIsDeleteModalVisible(false);
    setCurrentStep(0);
    setDisableStatus(false);
    setInheritorId("");
  };

  if (currentStep === 4) {
    return <SuccessText />;
  }

  return (
    <>
      <MainContainer
        ref={containerRef}
        onClick={(e) => MainContainerHandler(e)}
        $isVisible={isDeleteModalVisible}
      >
        <ModalSection>
          <XMarkImage onClick={xMarkHandler} src={XMark} alt="X mark" />
          <Header>
            {currentStep === 2
              ? t("settings.deactivation_feedback_header")
              : currentStep === 0
                ? t("settings.choose_inheritor_message")
                : t("settings.delete_account_message")}
          </Header>
          <Stepper
            currentStep={currentStep}
            setDisableStatus={setDisableStatus}
            setLeaveFeedbackText={setLeaveFeedbackText}
          />
          <ButtonsContainer>
            {backButtonChecker(
              activePractitionersListCount > 0,
              currentStep,
            ) ? null : (
              <CancelButton onClick={backButtonHandler}>Back</CancelButton>
            )}
            <DeleteButton
              $disabledStatus={disableStatus}
              onClick={DeactivateButtonHandler}
            >
              {isLoading ? (
                <Spinner />
              ) : currentStep === 3 ? (
                "Deactivate"
              ) : (
                "Continue"
              )}
            </DeleteButton>
          </ButtonsContainer>
        </ModalSection>
      </MainContainer>
    </>
  );
};

function Stepper(props: {
  currentStep: number;
  setDisableStatus(status: boolean): void;
  setLeaveFeedbackText(text: string): void;
}) {
  switch (props.currentStep) {
    case 0:
      return <ChooseLeadPhysicianStep />;
    case 1:
      return <PromptStep />;
    case 2:
      return <CheckBoxStep setDisableStatus={props.setDisableStatus} />;
    case 3:
      return (
        <FeedbackStep
          setDisableStatus={props.setDisableStatus}
          setLeaveFeedbackText={props.setLeaveFeedbackText}
        />
      );
    default:
      return null;
  }
}

export default DeactivateAccount;

function backButtonChecker(
  hasActivePractitioners: boolean,
  currentStep: number,
) {
  if (currentStep === 0) {
    return true;
  } else if (currentStep === 1 && !hasActivePractitioners) {
    return true;
  }
  return false;
}

const MainContainer = styled.div<{ $isVisible: boolean }>`
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: ${(props) => (props.$isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalSection = styled.div`
  position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 20px 0px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
`;

const Header = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #4e6276;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
`;

const CancelButton = styled.button`
  background-color: #ffffff;
  display: flex;
  width: 136px;
  height: 45px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #3a9cad;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #d0d5dd;
`;

const DeleteButton = styled.button<{ $disabledStatus: boolean }>`
  background-color: #ee505a;
  display: flex;
  width: 136px;
  height: 45px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #ffffff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  opacity: ${(props) => (props.$disabledStatus ? 0.5 : 1)};
`;

const XMarkImage = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
