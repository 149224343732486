import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1rem"
    height="1rem"
    viewBox="0 0 20 20"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M5.78328 4.16652C5.78306 4.60855 5.60726 5.03239 5.29454 5.34479C4.98182 5.6572 4.55781 5.83258 4.11578 5.83236C3.67376 5.83214 3.24992 5.65633 2.93752 5.34361C2.62511 5.0309 2.44973 4.60688 2.44995 4.16486C2.45017 3.72283 2.62598 3.29899 2.9387 2.98659C3.25141 2.67419 3.67542 2.4988 4.11745 2.49902C4.55948 2.49924 4.98331 2.67505 5.29572 2.98777C5.60812 3.30049 5.78351 3.7245 5.78328 4.16652ZM5.83328 7.06652H2.49995V17.4999H5.83328V7.06652ZM11.1 7.06652H7.78328V17.4999H11.0666V12.0249C11.0666 8.97486 15.0416 8.69152 15.0416 12.0249V17.4999H18.3333V10.8915C18.3333 5.74986 12.45 5.94152 11.0666 8.46652L11.1 7.06652Z"
      fill="currentColor"
    />
  </svg>
);
const LinkedIn = forwardRef(SvgComponent);
export default LinkedIn;
