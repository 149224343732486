import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sidebar } from "./styled";
import { faUser } from "@fortawesome/free-solid-svg-icons";
// import { faBell } from "@fortawesome/free-solid-svg-icons";
import { ITab } from "../../types";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  tab: ITab;
  setTab: (tab: ITab) => void;
}

const ProfileSidebar: FC<IProps> = ({ tab, setTab }) => {
  const { t } = useTranslation();

  return (
    <Sidebar>
      <p
        className={tab === "profile" ? "active" : ""}
        onClick={() => setTab("profile")}
      >
        <FontAwesomeIcon icon={faUser} />
        {t("settings.myProfile")}
      </p>
      {/* <p
        className={tab === "notifications" ? "active" : ""}
        onClick={() => setTab("notifications")}
      >
        <FontAwesomeIcon icon={faBell} />
        {t("settings.notifications")}
      </p> */}
    </Sidebar>
  );
};

export default ProfileSidebar;
