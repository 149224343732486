import { TextCnt, Header, Wrapper } from "./styled";

interface Props {
  header: string;
}

const ImpressumPage = ({ header }: Props) => {
  return (
    <Wrapper>
      <Header>{header}</Header>
      <TextCnt>
        <h3 className="copyRight">Digital Health Companion GmbH</h3>
        <p>Alte Leimengrube 14 | 68526 </p>
        <p>Ladenburg | Germany</p>
        <p>Telefon: +49-176-61127433</p>
        <p>
          E-Mail:{" "}
          <a href="mailto:support@dh-companion.com">support@dh-companion.com</a>
        </p>
      </TextCnt>
      <TextCnt>
        <p>Represented through:</p>
        <p>CEO Dr. Hannes Hudalla</p>
      </TextCnt>
      <TextCnt>
        <p>Register Entry:Registered in the commercial register</p>
        <p>Register Court: Mannheim</p>
        <p>Registration number: HRB 741203</p>
      </TextCnt>
      <TextCnt>
        <p>Professional Liability Insurance: Allianz Versicherungs-AG</p>
      </TextCnt>
      <TextCnt>
        <p>Responsible for the content:</p>
        <p>Dr. Hannes Hudalla</p>
        <p>Alte Leimengrube 14 | 68526 Ladenburg | Germany</p>
      </TextCnt>
    </Wrapper>
  );
};

export default ImpressumPage;
