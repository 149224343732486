import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
    clientId: z.string().optional().nullable(),
    postCode: z.string().optional(),
    city: z.string().optional(),
    country: z.string(),
  }),
);

type Response = z.infer<typeof schema>;

async function getCenters() {
  const { data } = await axiosInstance.get("admin/centers");
  return schema.parse(data);
}

export function useGetCentersQuery(options?: UseQueryOptions<Response>) {
  return useQuery<Response>("centers", getCenters, options);
}
