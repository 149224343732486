import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccordionSummary, AccordionDetails, Accordion } from "@mui/material";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import keycloakInstance from "../../../Keycloak";
import DeactivateAccount from "../DeactivateAccount";
import { createContext, useContext, useState } from "react";
import ChangeEmail from "../ChangeEmail";
import { useGetAllPractitionersQuery, useUser } from "../../../hooks/queries";

type deactivateSectionType = {
  isDeleteModalVisible: boolean;
  setIsDeleteModalVisible(status: boolean): void;
  currentStep: number;
  setCurrentStep(step: number): void;
  setDisableStatus(status: boolean): void;
  disableStatus: boolean;
  setInheritorId(id: string): void;
  inheritorId: string;
  isUserTeamLead: boolean;
  activePractitionersListCount: number;
};

const DeactivateSectionContext = createContext<null | deactivateSectionType>(
  null,
);

export function useDeactivateSectionContext() {
  const context = useContext(DeactivateSectionContext);
  if (!context) {
    throw new Error("Didn't found deactivate context");
  }

  return context;
}

const ExpertSettings = (props: { radius: string }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEmailSectionOpen, setIsEmailSectionOpen] = useState(false);
  const [disableStatus, setDisableStatus] = useState(false);
  const [inheritorId, setInheritorId] = useState("");

  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();

  const activePractitionersListCount =
    useGetAllPractitionersQuery({
      refetchOnWindowFocus: false,
    }).data?.data.filter((practitioner) => practitioner.active).length || 0;

  const isUserTeamLead =
    useUser().user?.roles.includes("EDUCATE_TEAM_LEAD") || false;

  const onResetPasswordClick = async () => {
    try {
      keycloakInstance.login({
        action: "UPDATE_PASSWORD",
      });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const DeactivateAccountButtonHandler = () => {
    setIsDeleteModalVisible(true);
    if (isUserTeamLead && activePractitionersListCount > 0) {
      setCurrentStep(0);
      setDisableStatus(true);
    } else {
      setCurrentStep(1);
    }
  };

  return (
    <AccordionSection $borderRadius={props.radius}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon color="#000" icon={faChevronDown} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <SummaryContainer>
          <h1>{t("settings.expert_settings")}</h1>
        </SummaryContainer>
      </AccordionSummary>
      <Details onClick={onResetPasswordClick}>
        <h2>Change password</h2>
        <FontAwesomeIcon color="#000" icon={faChevronRight} />
      </Details>
      <ChangeEmailDetailsSection>
        <ChangeEmailAddressSection
          onClick={() => setIsEmailSectionOpen(!isEmailSectionOpen)}
        >
          <h2
            style={{ color: `${isEmailSectionOpen ? "#5E95E6" : "#4e6276"}` }}
          >
            Change E-mail address
          </h2>
          {isEmailSectionOpen ? (
            <FontAwesomeIcon color="#5E95E6" icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon color="#000" icon={faChevronRight} />
          )}
        </ChangeEmailAddressSection>
        <ChangeEmail isEmailSectionOpen={isEmailSectionOpen} />
      </ChangeEmailDetailsSection>
      <Details onClick={DeactivateAccountButtonHandler}>
        <h2>Deactivate account</h2>
        <FontAwesomeIcon color="#000" icon={faChevronRight} />
      </Details>
      <DeactivateSectionContext.Provider
        value={{
          disableStatus: disableStatus,
          setDisableStatus: setDisableStatus,
          currentStep: currentStep,
          setCurrentStep: setCurrentStep,
          setIsDeleteModalVisible: setIsDeleteModalVisible,
          isDeleteModalVisible: isDeleteModalVisible,
          setInheritorId: setInheritorId,
          inheritorId: inheritorId,
          isUserTeamLead: isUserTeamLead,
          activePractitionersListCount: activePractitionersListCount,
        }}
      >
        <DeactivateAccount />
      </DeactivateSectionContext.Provider>
    </AccordionSection>
  );
};

export default ExpertSettings;

const AccordionSection = styled(Accordion)<{ $borderRadius: string }>`
  border-radius: ${(props) => props.$borderRadius} !important;
  box-shadow: none !important;
  &::before {
    background-color: transparent !important;
  }
  h2 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #4e6276;
    padding-left: 10px;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  flex-direction: column;
  h1 {
    align-self: baseline;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
  }
`;

const Details = styled(AccordionDetails)`
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ChangeEmailDetailsSection = styled(AccordionDetails)`
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
`;

const ChangeEmailAddressSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
