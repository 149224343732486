import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ProfileTab from "../Tabs/ProfileTab";
import { faUser, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ExpertSettings from "../ExpertSettings";

function ProfileMobileVersion() {
  const [expanded, setExpanded] = useState("panel1");

  const { t } = useTranslation();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : "");
    };
  return (
    <MainContainer>
      <AccordionSection
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon color="#000" icon={faChevronDown} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <SummaryContainer>
            <div>
              {t("settings.my_settings")} <FontAwesomeIcon icon={faUser} />
            </div>
            <p>Customize your profile settings according to your preferences</p>
          </SummaryContainer>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileTab discardButtonHandler={() => setExpanded("")} />
        </AccordionDetails>
      </AccordionSection>
      <AccordionSection
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <ExpertSettings radius="20px" />
      </AccordionSection>
    </MainContainer>
  );
}

export default ProfileMobileVersion;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (width >= 768px) {
    display: none;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  flex-direction: column;
  div {
    align-self: baseline;
  }
  p {
    color: #8f9195;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
`;

const AccordionSection = styled(Accordion)`
  border-radius: 20px !important;
  margin: 0 12px !important;
  box-shadow: none !important;
  &::before {
    background-color: transparent !important;
  }
`;
