import { z } from "zod";

export const registrationCode =
  process.env.REACT_APP_REGISTRATION_CODE_ONE4DMD + "";

export const RegistrationFormSchema = z.object({
  name: z
    .string({ required_error: "Field is required" })
    .min(1, { message: "Field is required" }),
  surname: z
    .string({ required_error: "Field is required" })
    .min(1, { message: "Field is required" }),
  email: z
    .string({ required_error: "Field is required" })
    .min(1, { message: "Field is required" })
    .email({ message: "Correct email is required" }),
});

export interface RegistrationDto {
  name: string;
  surname: string;
  email: string;
  title: string;
  city: string;
  country: string;
}

export type RegistrationFormType = z.infer<typeof RegistrationFormSchema>;
