import React from "react";
import styled from "styled-components";
import { Colors } from "../../Contstants";

const TermsOfUse = () => {
  return (
    <Container>
      <MainHeader>General Terms of Use for One4Educate</MainHeader>
      <Paragraph>
        Use of One4 is subject to the following general terms of use. Please
        read the Terms of Use carefully before using One4. By starting One4, you
        agree to the terms of use.
      </Paragraph>

      <Heading>1. General</Heading>
      <Paragraph>
        As a digital application, One4 offers educational content for Health
        Care Professionals (HCPs). The content of the educational material is
        not reviewed by DHC, and the corresponding providing party shall be
        responsible for its accuracy. All content is intended for informational
        use only. It is not a substitute for the advice or treatment of a
        licensed physician.
      </Paragraph>
      <Paragraph>
        One4 is not suitable for making independent diagnoses or starting
        treatment. Nor is the information provided to be understood as a request
        for a specific treatment or particular treatment or non-treatment of a
        possible disease. One4 does not provide prevention, monitoring,
        prediction, prognosis, treatment, or alleviation of diseases as defined
        within the meaning of Chapter 1, Article 2.1. of EU Regulation 2017/745.
        Although One4 is used in a medical context, it serves the sole purpose
        of education and information (Purpose).
      </Paragraph>
      <Paragraph>
        One4 is used by natural persons of legal age who are HCPs (physicians,
        nurses, pharmacists). Use outside this group of persons is not
        permitted.
      </Paragraph>
      <Paragraph>
        DHC reserves the right to modify the Terms of Use and to adjust them to
        technical or legal developments and new service offerings. DHC will
        provide notice of any changes to the Terms of Use by posting a notice on
        its website. If you provide DHC with an e-mail address you will receive
        a notice to that effect.
      </Paragraph>
      <Paragraph>
        One4 provides the ability to access third party services. DHC merely
        provides the users of One4 access for these offers, without being a
        provider itself. The corresponding offers are highlighted as such within
        One4. Separate terms of use of the respective provider apply. DHC does
        not assume any responsibility for the content of third parties.
      </Paragraph>

      <Heading>2. Personal Data</Heading>
      <Paragraph>
        Personal data is transmitted or made available with One4. You declare to
        DHC your consent to the use of the data subject to your agreement to our
        Privacy Policy.
      </Paragraph>
      <Paragraph>
        You authorize DHC to use data that is appropriately identified in the
        Privacy Notice and/or within the App in aggregated and anonymized form
        for commercial use. You grant DHC for this purpose with the submission
        of the respective data in each case a temporally unlimited and
        geographically unlimited, transferable right of use.
      </Paragraph>

      <Heading>3. Safety</Heading>
      <Paragraph>You can contribute to the security of your data:</Paragraph>
      <List>
        <ListItem>
          You are obligated to ensure that no unauthorized third party comes
          into possession of the login credentials (user name and password). If
          you suspect that someone has obtained these or that they are being
          used without authorization, you must immediately notify DHC by e-mail
          <Link href="mailto:support@dh-companion.com">
            support@dh-companion.com
          </Link>
        </ListItem>
        <ListItem>
          Always keep your endpoint operating system up to date so it has the
          highest possible protection.
        </ListItem>
      </List>
      <Paragraph>
        DHC shall not be liable for any damages resulting from your breach of
        the foregoing duties of care.
      </Paragraph>

      <Heading>4. No Warranty or Guarantee, Liability</Heading>
      <Paragraph>One4 is provided without warranty of any kind.</Paragraph>
      <Paragraph>
        DHC does not guarantee accessibility, technical usability, freedom from
        malware of One4, the accuracy of content or the data transmitted.
      </Paragraph>
      <Paragraph>
        DHC is not liable for technical malfunctions on your terminal device. In
        addition, the statutory provisions apply.
      </Paragraph>

      <Heading>5. Responsible Body and Operator of One4</Heading>
      <Paragraph>
        One4 is a product of and operated by Digital Health Companion GmbH
        (DHC). For questions, feedback, or complaints, please feel free to
        contact us at any time. Alte Leimengrube 14, 68526 Ladenburg, Germany.
      </Paragraph>

      <Heading>6. Applicable Law, Place of Jurisdiction</Heading>
      <Paragraph>
        The relationship between you and DHC shall be governed by the laws of
        the Federal Republic of Germany. The place of jurisdiction shall be
        exclusively Heidelberg, insofar as this is legally permissible.
      </Paragraph>

      <Paragraph>Status 01/2024</Paragraph>
      <Paragraph>© Digital Health Companion GmbH</Paragraph>
    </Container>
  );
};

export default TermsOfUse;

const Container = styled.div`
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  padding: 0 10px;
`;

const MainHeader = styled.h2`
  color: ${Colors.brand};
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const Heading = styled.h2`
  color: ${Colors.brand};
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
  color: #4e6276;
`;

const List = styled.ul`
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
  color: #4e6276;
`;

const Link = styled.a`
  color: ${Colors.textDark};
  text-decoration: underline;
`;
