import React, { useEffect, useState } from "react";
import { getMonthlyDailyActiveUsers } from "../../../services/countly.service";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface SessionsType {
  uniqueSessionsMonth: number;
  uniqueSessionsToday: number;
  newSessionsMonth: number;
  newSessionsToday: number;
}

const textStrings = {
  activeUsersTitle: "Daily and monthly sessions info",
  loading: "Loading sessions users analytics...",
  today: "Today",
  activeUsersCount: "Active Users",
  newUsersCount: "New Users",
};

export default function SessionsInfoWidget() {
  const [sessions, setSessions] = useState<SessionsType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const sessionsData = await getMonthlyDailyActiveUsers();
      setSessions(sessionsData);
    } catch (error) {
      console.error("Error fetching sessions data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="feedbacks-count-widget-container">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h5">{textStrings.activeUsersTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{new Date().toLocaleString("en-US", { month: "long" })}</TableCell>
                    <TableCell>{textStrings.today}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{textStrings.activeUsersCount}</TableCell>
                    <TableCell>{sessions?.uniqueSessionsMonth}</TableCell>
                    <TableCell>{sessions?.uniqueSessionsToday}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{textStrings.newUsersCount}</TableCell>
                    <TableCell>{sessions?.newSessionsMonth}</TableCell>
                    <TableCell>{sessions?.newSessionsToday}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
