import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { AvatarBox, AvatarInfoCnt, FlexBtnCnt } from "./styled";
import Button from "../Button";
import { ChangeEventHandler, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalV3 from "../Modalv3";
import { styled } from "styled-components";
import { Colors } from "../../Contstants";
import { useAddAvatarImgUploadMutation } from "../../hooks/mutations/useAddAvatarImgUploadMutation";
import { queryClient } from "../../queryClient";
import { useDeleteAvatarImgMutation } from "../../hooks/mutations/useDeleteAvatarImgMutation";
import { useUser } from "../../hooks/queries";
import { AxiosError } from "axios";

interface userInfo {
  name: string;
  lastName: string;
}

const AvatarInfo = ({ name, lastName }: userInfo) => {
  const { t } = useTranslation();
  const refInput = useRef<HTMLInputElement>(null);

  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { user } = useUser();

  const uploadMutation = useAddAvatarImgUploadMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("User");
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        setIsErrorModalVisible(true);
        if (error.response?.data) {
          setErrorDescription(error.response.data);
        } else {
          setErrorDescription("Something went wrong");
        }
      }
    },
  });

  const deleteMutation = useDeleteAvatarImgMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("User");
      setIsDeleteModalVisible(false);
    },
  });

  const fileSelectedHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedFile = e.target.files?.[0];

    if (!selectedFile) {
      return;
    }

    if (selectedFile.size > 5000000) {
      setIsErrorModalVisible(true);
      e.target.value = "";
      return;
    }
    const fd = new FormData();
    fd.append("avatar", selectedFile, selectedFile.name);

    uploadMutation.mutate({ avatar: fd });
    e.target.value = "";
  };

  return (
    <>
      <AvatarInfoCnt>
        <div>
          {!!user?.avatar ? (
            <img src={user?.avatar} alt="Avatar" />
          ) : (
            <FontAwesomeIcon icon={faCircleUser} />
          )}
          <AvatarBox>
            <div>
              {name} {lastName}
            </div>
          </AvatarBox>
        </div>

        <FlexBtnCnt>
          <input
            ref={refInput}
            type="file"
            name="image"
            accept="image/jpeg, image/png, image/webp, image/gif, image/jpg"
            style={{ display: "none" }}
            onChange={fileSelectedHandler}
          />
          <Button
            onClick={() => refInput?.current?.click()}
            title={t("settings.change_avatar")}
            green={true}
            small={true}
          />

          <Button
            title={t("settings.delete")}
            light={true}
            small={true}
            disabled={
              user?.avatar ? user.avatar.indexOf("__dummy__") >= 0 : true
            }
            onClick={() => setIsDeleteModalVisible(true)}
          />
        </FlexBtnCnt>
      </AvatarInfoCnt>
      <ModalV3
        isErrorCircleVisible={true}
        isVisible={isErrorModalVisible}
        onClose={() => setIsErrorModalVisible(false)}
        description={
          errorDescription ? errorDescription : t("settings.file_size_message")
        }
      ></ModalV3>
      <ModalV3
        isErrorCircleVisible={false}
        headerTextDescription="Delete avatar"
        isVisible={isDeleteModalVisible}
        closeButtonIsHidden={true}
        onClose={() => setIsDeleteModalVisible(false)}
        description={t("settings.question_message")}
      >
        <ButtonsContainer>
          <CancelButton onClick={() => setIsDeleteModalVisible(false)}>
            Cancel
          </CancelButton>
          <DeleteButton onClick={() => deleteMutation.mutate()}>
            Delete
          </DeleteButton>
        </ButtonsContainer>
      </ModalV3>
    </>
  );
};
export default AvatarInfo;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const CancelButton = styled.button`
  background-color: #ffffff;
  display: flex;
  width: 136px;
  height: 45px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${Colors.newBlue};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #d0d5dd;
`;

const DeleteButton = styled.button`
  background-color: #ee505a;
  display: flex;
  width: 136px;
  height: 45px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #ffffff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  border: none;
`;
