import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Chart, ArcElement } from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./BounceRateWidget.css";
Chart.register(ArcElement);

interface Record {
  url: string;
  bounceRate: number;
}

interface BounceRateWidgetProps {
  records: Record[];
}

const BounceRateWidget: React.FC<BounceRateWidgetProps> = ({ records }) => {
  const [selectedRecord, setSelectedRecord] = useState<Record | null>(null);
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    if (selectedRecord) {
      const data = {
        datasets: [
          {
            data: [selectedRecord.bounceRate, 100 - selectedRecord.bounceRate],
            backgroundColor: ["#FF6384", "#36A2EB"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB"],
          },
        ],
      };
      setChartData(data);
    }
  }, [selectedRecord]);

  const chartOptions = {
    aspectRatio: 3,
  };

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h5>Bounce rate</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%", padding: "20px" }}>
              <p>
                Percentage of visitors who have landed on a page without
                visiting other pages
              </p>
              <h3>Pages</h3>
              <ul>
                {records &&
                  records.map((record, index) => (
                    <li
                      key={index}
                      onClick={() => setSelectedRecord(record)}
                      style={{
                        cursor: "pointer",
                        color: selectedRecord === record ? "red" : "black",
                      }}
                    >
                      <p
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {record.url}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
            <div style={{ width: "50%", padding: "20px" }}>
              {chartData && (
                <h3 className="text-center">
                  Bounce Rate - {selectedRecord?.url}
                </h3>
              )}
              <div className="relative">
                {chartData ? (
                  <Doughnut data={chartData} options={chartOptions} />
                ) : (
                  <p>Select an URL to visualize bounce rate.</p>
                )}
                <div className="absolute-center text-center">
                  {chartData && (
                    <p id="bounce-rate-percentage">
                      {selectedRecord?.bounceRate}%
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BounceRateWidget;
