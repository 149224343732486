import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

function deleteAvatarImg() {
  return axiosInstance.delete("account/avatar", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function useDeleteAvatarImgMutation(
  options?: Omit<UseMutationOptions<unknown, unknown, unknown>, "mutationFn">,
) {
  return useMutation<unknown, unknown>(deleteAvatarImg, options);
}
