import { styled } from "styled-components";
import { Colors } from "../../Contstants";

interface Iprops {
  header: string;
}

const InfoPanel = ({ header }: Iprops) => {
  return (
    <Container>
      <MainHeader>{header}</MainHeader>
      <Paragraph>
        Basic Information on Data Protection in the context of using One4Educate
        ("One4")
      </Paragraph>
      <Paragraph>
        The responsible party for data protection is Digital Health Companion
        GmbH ("DHC"), Alte Leimengrube 14, 68526 Ladenburg, Germany. You can
        reach our data protection officer at{" "}
        <Link href="mailto:support@dh-companion.com">support@dh-companion.com</Link>.
      </Paragraph>
      <Paragraph>
        We take the protection of your personal data very seriously. This data
        protection notice informs you about the nature, scope, and purpose of
        the collection and use of personal data.
      </Paragraph>

      <Heading>1. Legal Basis of Data Processing</Heading>
      <Paragraph>
        Data is processed by us on the following basis, depending on the
        application:
      </Paragraph>
      <List>
        <ListItem>
          Consent according to Art. 6 para. 1 lit. a and Art. 7 GDPR
        </ListItem>
        <ListItem>
          Fulfillment of services and implementation of contractual measures as
          well as answering inquiries according to Art. 6 para. 1 lit. b GDPR
        </ListItem>
        <ListItem>
          Fulfillment of legal obligations according to Art. 6 para. 1 lit. c
          GDPR
        </ListItem>
        <ListItem>
          On the basis of legitimate interests pursuant to Art. 6 Para. 1 lit. f
          GDPR
        </ListItem>
      </List>

      <Heading>2. Passing on to Third Parties</Heading>
      <Paragraph>
        Your data will only be passed on to third parties if you consent to this
        or if the law permits this. This may be the case pursuant to Art. 6
        Para. 1 lit. b, c, or f GDPR.
      </Paragraph>
      <Paragraph>
        Insofar as we commission third parties with the processing of data on
        the basis of a so-called "order processing agreement," this is done on
        the basis of Art. 28 GDPR.
      </Paragraph>

      <Heading>3. Processing in Third Countries</Heading>
      <Paragraph>
        In some cases, data is processed by service providers in third
        countries, i.e. countries outside the European Union (EU) or the
        European Economic Area (EEA). Such processing only takes place if the
        specific requirements of Art. 44 GDPR are met. An adequate level of data
        protection is ensured, as there are either specific contractual
        obligations or adequate guarantees that a level of data protection
        comparable to the EU exists.
      </Paragraph>

      <Heading>4. Deletion of Data</Heading>
      <Paragraph>
        We restrict the processing of personal data in accordance with Art. 17
        and 18 GDPR. Insofar as no retention obligations exist, we delete stored
        data when they are no longer needed for their intended purpose. If
        stored data may not be deleted, we ensure that it is only processed in
        accordance with its intended purpose.
      </Paragraph>

      <Heading>5. Automatically Collected Data</Heading>
      <Paragraph>
        In principle, you do not have to tell us who you are when you use the
        One4. However, your end device can only call up One4 by processing your
        IP address.
      </Paragraph>

      <Heading>5.1 Data in Log Files</Heading>
      <Paragraph>
        The following data is stored: Date and time of the communication
        process, amount of data transferred, message about successful retrieval,
        type of end device, your operating system, your IP address, and your
        Internet service provider.
      </Paragraph>
      <Paragraph>
        We carry out this data processing in accordance with Art. 6 Para. 1 lit.
        f GDPR. The collection serves IT security and protection against
        unauthorized use. We will check this data subsequently if there are
        concrete indications of unlawful use. The data will continue to be
        stored insofar as this is necessary to clarify a specific incident.
      </Paragraph>

      <Heading>5.2 Cookies</Heading>
      <Paragraph>
        We may use so-called cookies, among other things to provide you with
        services, in particular to ensure your registration or login status in
        One4.
      </Paragraph>
      <Paragraph>
        Cookies are small text files that are stored on the end device of users
        and may contain data about the respective user to enable access to
        various functions, among other things. Cookies are stored on the end
        device used in each case and may be read by us from here. Consequently,
        you have control over the use of cookies. By changing the settings, you
        may be able to deactivate or restrict the transmission of cookies and,
        for example, reject cookies from third parties or cookies in general.
        However, if you disable cookies for our services, you may not be able to
        use the offered functions or services or not to the full extent.
      </Paragraph>
      <Paragraph>
        We use necessary cookies, which are required to enable the provision of
        the services owed by us or to ensure the functionality of our services.
        The legal basis for setting these cookies is Section 25 (2) No. 2 TTDSG.
        The processing of personal data possibly carried out in this context is
        then based on Art. 6 para. 1 p. 1 lit. b GDPR, which permits the
        processing of data for the performance of a contract or pre-contractual
        measures, or according to Art. 6 para. 1 p. 1 lit. f GDPR, which permits
        data processing to safeguard the legitimate interests of the controller,
        unless the interests or the fundamental rights and freedoms of the data
        subject outweigh the interest of the controller in the data processing.
        Our interest then lies in ensuring the provision of the functions of our
        services.
      </Paragraph>
      <Paragraph>
        For the use of other, non-required cookies, we may obtain your consent.
        The setting of cookies is then based on your consent in accordance with
        §25 para. 1 TTDSG, any processing of personal data carried out in this
        context in accordance with Art. 6 para. 1 p. 1 lit. a GDPR. You may
        revoke your consent at any time. The legality of the data processing
        already carried out on the basis of your consent remains unaffected by
        the revocation.
      </Paragraph>

      <Heading>6. Consciously Transmitted Data</Heading>

      <Heading>6.1 Contacting</Heading>
      <Paragraph>
        After contacting us by e-mail, letter, telephone, or via the contact
        form, your data will be transmitted to us. The personal data transmitted
        when contacting us will be stored and processed.
      </Paragraph>

      <Heading>6.2 Data Processing and Transfer when using One4</Heading>
      <Paragraph>
        By starting One4 for the first time, you explicitly agree to the
        processing of your data and the transfer of data to third parties.
      </Paragraph>
      <Paragraph>
        Mandatory user data are your username and email address. These are
        required to create the account.
      </Paragraph>
      <Paragraph>
        Optional user data are additional profile data like profession, medical
        center, or profile pictures.
      </Paragraph>

      <Heading>7. Your Rights</Heading>

      <Heading>7.1 Revocation</Heading>
      <Paragraph>
        You can revoke your consent at any time with effect for the future. An
        informal message is sufficient for this purpose.
      </Paragraph>

      <Heading>7.2 Objection</Heading>
      <Paragraph>
        You can also object to the processing of your personal data at any time
        in accordance with the legal requirements.
      </Paragraph>

      <Heading>
        7.3 Information, Blocking, Deletion, Completion, and Restriction of
        Processing
      </Heading>
      <Paragraph>
        Artt. 15 - 18 GDPR contain your rights to information, blocking,
        deletion, completion of the data concerning you as well as to
        restriction of processing.
      </Paragraph>

      <Heading>7.4 Data Portability</Heading>
      <Paragraph>
        Art. 20 GDPR contains the right to have data that is processed
        automatically handed over to oneself or to a third party in a common,
        machine-readable format. This shall only be done within the scope of
        what is technically feasible.
      </Paragraph>

      <Heading>7.5 Complaint to Supervisory Authority</Heading>
      <Paragraph>
        If you suspect unlawful data processing, you are free to file a
        complaint with the competent supervisory authority. Further information
        is available on the website of the Federal Data Protection Commissioner.
      </Paragraph>

      <Paragraph>Status 01/2024</Paragraph>
      <Paragraph>© Digital Health Companion GmbH</Paragraph>
    </Container>
  );
};

export default InfoPanel;

const Container = styled.div`
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  padding: 0 10px;
`;

const MainHeader = styled.h2`
  color: ${Colors.brand};
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const Heading = styled.h2`
  color: ${Colors.brand};
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
  color: #4e6276;
`;

const List = styled.ul`
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
  color: #4e6276;
`;

const Link = styled.a`
  color: ${Colors.textDark};
  text-decoration: underline;
`;
