import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
    description: z.string(),
    url: z.string(),
    isGlobal: z.boolean(),
    country: z.string(),
    language: z.string(),
    clientId: z.string(),
    isPreProduction: z.boolean(),
  }),
);

export type CoursesResponse = z.infer<typeof schema>;

function getAllLmsCourses() {
  return axiosInstance.get("admin/branches").then((res) => res.data);
}

export function useGetAllLmsCoursesQuery(
  options?: UseQueryOptions<CoursesResponse>,
) {
  return useQuery<CoursesResponse>("allCourses", getAllLmsCourses, options);
}
