import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    id: z.string(),
    value: z.string(),
  }),
);

type Response = z.infer<typeof schema>;

async function getProfessions() {
  const { data } = await axiosInstance.get("list/roles");
  return schema.parse(data);
}

export function useGetProfessionsQuery(options?: UseQueryOptions<Response>) {
  return useQuery<Response>("professions", getProfessions, options);
}
