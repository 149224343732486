import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

interface BaseMutationArguments {
  title: string;
  clientId: string;
  postCode: string;
  city: string;
  country: string;
  id?: string;
}

interface EditMutationArguments extends BaseMutationArguments {
  id: string;
  edit?: true;
}

type MutationArguments = BaseMutationArguments | EditMutationArguments;

function mutationFn(args: MutationArguments) {
  if ("edit" in args && args.edit) {
    delete args["edit"];
    return axiosInstance.put(`admin/center/${args.id}`, args);
  } else {
    return axiosInstance.post("admin/center", args);
  }
}

export function useCreateOrModifyCenterMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, MutationArguments>,
    "mutationFn"
  >,
) {
  return useMutation(mutationFn, options);
}
