import React from "react";
import { Typography, Box } from "@mui/material";
import CountUp from "react-countup";

interface Props {
  totalUsers: number;
}

const UserStatsWidget: React.FC<Props> = ({ totalUsers }) => {
  return (
    <div>
      <Box display="flex" alignItems="baseline">
      <Typography variant="h4">Total Users: </Typography>
        <CountUp start={0} end={totalUsers} duration={2} separator="," decimals={0}>
          {({ countUpRef }) => (
            <Typography variant="h4" ref={countUpRef} />
          )}
        </CountUp>
      </Box>
    </div>
  );
};

export default UserStatsWidget;
