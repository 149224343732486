import React, { useEffect, useState } from "react";
import { getSentFeedbacksCount } from "../../../services/countly.service";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FeedbacksCountWidget.css";

const textStrings = {
  feedbacksTitle: "How many users gave feedback / contacted DHC?",
  loadingFeedbacks: "Loading feedbacks analytics...",
  today: "Today",
  feedbacksCount: "Feedbacks Count",
};

interface FeedbacksType {
  feedbacksCurrentYear: number;
  feedbacksCurrentMonth: number;
  feedbacksToday: number;
}

export default function FeedbacksCountWidget() {
  const [feedbacks, setFeedbacks] = useState<FeedbacksType | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const receivedFeedbacks = await getSentFeedbacksCount();
    if (!receivedFeedbacks) {
      setIsLoading(false);
      return;
    }

    setFeedbacks(receivedFeedbacks);
    setIsLoading(false);
  };

  return (
    <div className="feedbacks-count-widget-container">
      {!isLoading && feedbacks ? (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h5>{textStrings.feedbacksTitle}</h5>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className="table-heading">
                      {new Date().getFullYear().toString()}
                    </TableCell>
                    <TableCell className="table-heading">
                      {new Date().toLocaleString("en-US", { month: "long" })}
                    </TableCell>
                    <TableCell className="table-heading">
                      {textStrings.today}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="border text-left">
                      {textStrings.feedbacksCount}
                    </TableCell>
                    <TableCell className="border text-left">
                      {feedbacks.feedbacksCurrentYear}
                    </TableCell>
                    <TableCell className="border text-left">
                      {feedbacks.feedbacksCurrentMonth}
                    </TableCell>
                    <TableCell className="border text-left">
                      {feedbacks.feedbacksToday}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className="loading-container">
          <CircularProgress />
          <Typography>{textStrings.loadingFeedbacks}</Typography>
        </div>
      )}
    </div>
  );
}
