const DateFormatter = (date: string) => {
  const formattedDate = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date(date));
  return formattedDate;
};

export default DateFormatter;
