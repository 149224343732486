import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { Outlet } from "react-router-dom";
import keycloakInstance, { initOptions } from "../Keycloak";
import { useEffect } from "react";
import { styled } from "styled-components";
import Spinner from "../components/Spinner";
import { useUser } from "../hooks/queries";
import { Countly } from "../services/countly.service";

function ProtectedRoutes() {
  const { keycloak, initialized } = useKeycloak();

  const { user, refetch } = useUser({
    enabled: false,
  });

  const isSignedIn = Boolean(user);

  useEffect(() => {
    if (!isSignedIn) return;

    Countly.group_features({
      all: [
        "sessions",
        "events",
        "views",
        "scrolls",
        "clicks",
        "forms",
        "crashes",
        "attribution",
        "users",
      ],
    });

    Countly.add_consent("all");

    Countly.q.push(["opt_in"]);

    return () => {
      Countly.q.push(["opt_out"]);
    };
  }, [isSignedIn]);

  useEffect(() => {
    if (isSignedIn || !initialized) return;
    refetch();
  }, [initialized, isSignedIn, refetch]);

  if (initialized && !keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  if (!isSignedIn || !initialized) {
    return (
      <Wrapper>
        <Spinner isSpinnerCentered />
      </Wrapper>
    );
  }

  return <Outlet />;
}

export function ProtectedRoutesWrapper() {
  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      initOptions={initOptions}
    >
      <ProtectedRoutes />
    </ReactKeycloakProvider>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;
