import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

interface BaseMutationArguments {
  centerId: string;
  email: string;
}

interface EditMutationArguments extends BaseMutationArguments {
  id: string;
  edit?: true;
}

type MutationArguments = BaseMutationArguments | EditMutationArguments;

function mutationFn(args: MutationArguments) {
  if ("edit" in args && args.edit) {
    delete args["edit"];
    return axiosInstance.patch(`admin/invitation/${args.id}`, args);
  } else {
    return axiosInstance.post("admin/invitation", args);
  }
}

export function useCreateOrModifyLeadPhysicianMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, MutationArguments>,
    "mutationFn"
  >,
) {
  return useMutation(mutationFn, options);
}
