import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    title: z.string(),
    description: z.string(),
    body:
      z.object({
        type: z.string(),
        content: z.string(),
      }) || z.string(),
  }),
);

type Response = z.infer<typeof schema>;

async function useGetWizardDescription() {
  const { data } = await axiosInstance.get("wizard/ONE4EDUCATE");
  return schema.parse(data);
}

export function useGetWizardDescriptionQuery(
  options?: UseQueryOptions<Response>,
) {
  return useQuery<Response>("wizard", useGetWizardDescription, options);
}
