import { useState } from "react";
import AvatarInfo from "../AvatarInfo";
import TabHeader from "../TabHeader";
import Modal from "../Modal";
import { useTranslation } from "react-i18next";
import { PersonCnt, PersonFlex } from "../styled";
import { FormInput } from "../../../form";
import Input from "../../Input";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "styled-components";
import SelectDropdown from "../../SelectDropdown";
import { useUser } from "../../../hooks/queries";
import { useModifyUserMutation } from "../../../hooks/mutations/useModifyUserMutation";
import { queryClient } from "../../../queryClient";
import { CircularProgress } from "@mui/material";

const PersonDetailsSchema = z.object({
  name: z.string().min(1),
  surname: z.string().min(1),
  language: z.string().min(1),
});

type PersonDetailsForm = z.infer<typeof PersonDetailsSchema>;

const tempCountries = [{ value: "English", id: "en" }];

const ProfileTab = (props: { discardButtonHandler?: () => void }) => {
  const { user } = useUser({
    onSuccess: (response) => {
      reset({
        name: response.name,
        surname: response.surname,
        language: response.language,
      });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
    reset,
  } = useForm<PersonDetailsForm>({
    resolver: zodResolver(PersonDetailsSchema),
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      name: user?.name,
      surname: user?.surname,
      language: user?.language,
    },
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const { mutate, isLoading } = useModifyUserMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("User");
    },
  });

  const onValid = async ({ name, surname, language }: PersonDetailsForm) => {
    mutate({
      name,
      surname,
      language,
    });
  };

  const setLanguage = (value: string) => {
    setValue("language", value);
  };

  return (
    <div>
      <TabHeader
        title={t("settings.my_settings")}
        text={t("settings.customize_profile")}
        icon="avatar"
        updated={isDirty}
        save={handleSubmit(onValid, console.warn)}
        isLoading={isLoading}
      />

      <AvatarInfo name={user?.name ?? ""} lastName={user?.surname ?? ""} />

      <PersonCnt>
        <PersonFlex>
          <FormInput
            control={control}
            label={t("settings.first_name")}
            name="name"
            placeholder={t("settings.enter_name")}
          />
          <FormInput
            control={control}
            label={t("settings.last_name")}
            name="surname"
            placeholder={t("settings.enter_last_name")}
          />
          <Input
            value={user?.profession ?? ""}
            label={t("settings.profession")}
            name="chooseRole"
            disabled
          />
        </PersonFlex>
        <DropDownContainer>
          <Input
            label={t("settings.institution")}
            name="Institution"
            value={user?.center ?? ""}
            disabled
          />
          <SelectDropdown
            defaultValue="en"
            options={tempCountries}
            onChange={setLanguage}
            title={t("settings.select_language")}
            backgroundColor="#ffffff"
          />
          <div />
        </DropDownContainer>
        <ButtonsSection>
          <SaveChangesButton onClick={handleSubmit(onValid, console.warn)}>
            {isLoading ? (
              <CircularProgress
                color="inherit"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              t("settings.save_changes")
            )}
          </SaveChangesButton>
          <DiscardChangesButton onClick={props.discardButtonHandler}>
            {t("settings.discard_changes")}
          </DiscardChangesButton>
        </ButtonsSection>
      </PersonCnt>
      <Modal
        title={t("settings.saved")}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ProfileTab;

const DropDownContainer = styled.div`
  @media (width >= 770px) {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 41.5rem;
  }
`;

const ButtonsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 12px;
  @media (width > 768px) {
    display: none;
  }
`;

const SaveChangesButton = styled.button`
  width: 123px;
  height: 36px;
  border-radius: 4px;
  background-color: #5080f4;
  color: #fefefe;
  border: none;
`;

const DiscardChangesButton = styled.button`
  width: 83px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  background-color: #ffffff;
  color: #051123;
`;
