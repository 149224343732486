import React from "react";
import BarChartWidget from "../BarChartWidget";
import { getUsersPerRole, getUsersPerCenter, getUsersPerCountry, getUsersPerPostalCode } from "../../../services/countly.service";
import { Grid, Paper } from "@mui/material";

const MultiBarChartLayout: React.FC = () => {
  return (
    <div className="multi-bar-chart-system">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className="chart">
            <BarChartWidget title="Users Per Role" fetchData={getUsersPerRole} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className="chart">
            <BarChartWidget title="Users Per Center" fetchData={getUsersPerCenter} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className="chart">
            <BarChartWidget title="Users Per Country" fetchData={getUsersPerCountry} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className="chart">
            <BarChartWidget title="Users Per Postal Code" fetchData={getUsersPerPostalCode} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default MultiBarChartLayout;
