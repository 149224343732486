import { useEffect } from "react";
import ActivePanel from "../../components/ActivePanel";
import { pushCustomPageViewEventToCountly } from "../../services/countly.service";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import styled from "styled-components";
const Active = () => {
  const location = useLocation();

  // keep this useEffect as is, important for countly statistics
  useEffect(() => {
    const startTime = new Date();
    return pushCustomPageViewEventToCountly(startTime, location.pathname);
  }, [location]);

  return (
    <Container>
      <ActivePanel />
      <Footer />
    </Container>
  );
};

export default Active;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
`;
