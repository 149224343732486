import React, { ChangeEvent, useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";

const FeedbackStep = (props: {
  setLeaveFeedbackText(text: string): void;
  setDisableStatus(status: boolean): void;
}) => {
  const [selectedValue, setSelectedValue] = useState("female");
  const [textFieldValue, setTextFieldValue] = useState("");

  const { t } = useTranslation();

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    props.setDisableStatus(false);
    props.setLeaveFeedbackText(event.target.value);
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextFieldValue(event.target.value);
    props.setLeaveFeedbackText(event.target.value);
  };

  return (
    <Container>
      <h2>{t("settings.deactivation_sub_text")}</h2>
      <div>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="I don't like your service"
              control={<Radio />}
              label="I don't like your service"
            />
            <FormControlLabel
              value="It was mistake"
              control={<Radio />}
              label="It was mistake"
            />
            <FormControlLabel
              value="No comment"
              control={<Radio />}
              label="No comment"
            />
            <FormControlLabel
              value="Something else"
              control={<Radio />}
              label="Something else"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {selectedValue === "Something else" && (
        <FeedbackTextField
          label="Write here ..."
          multiline
          rows={4}
          value={textFieldValue}
          onChange={handleTextFieldChange}
        />
      )}
    </Container>
  );
};

export default FeedbackStep;

const Container = styled.div`
  margin-bottom: 14px;
  h2 {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 25px;
  }
  textarea {
    width: 588px;
    height: 110px;
    resize: none;
  }
`;

const FeedbackTextField = styled(TextField)`
  max-width: 320px;
  @media (min-width: 768px) {
    max-width: 580px;
  }
`;
