import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    appId: z.string(),
    accountId: z.string(),
    createdAt: z.string(),
    deletedAt: z.string().nullable().optional(),
  }),
);

type Response = z.infer<typeof schema>;

async function getUserConsent() {
  const { data } = await axiosInstance.get("account/consents");
  return schema.parse(data);
}

export function useGetUserConsentQuery(options?: UseQueryOptions<Response>) {
  return useQuery<Response>("professions", getUserConsent, options);
}
