import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ImpressumPage from "../../components/Impressum";
import Layout from "../../components/Layout";
import { pushCustomPageViewEventToCountly } from "../../services/countly.service";

const Impressum = () => {
  const location = useLocation();

  // keep this useEffect as is, important for countly statistics
  useEffect(() => {
    const startTime = new Date();
    return pushCustomPageViewEventToCountly(startTime, location.pathname);
  }, [location]);

  return (
    <Layout>
      <ImpressumPage header="Imprint" />
    </Layout>
  );
};

export default Impressum;
