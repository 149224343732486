import { z } from "zod";
import { ACCESS_RIGHTS, ROLES } from "../../constants";

export const userSchema = z.object({
  birthDate: z.string().nullable().optional(),
  id: z.string(),
  realm: z.string(),
  keycloakId: z.string(),
  roles: z.array(z.enum(ROLES)),
  email: z.string(),
  name: z.string(),
  surname: z.string(),
  receiveNewsletter: z.boolean().optional(),
  privacyStatementConfirmationTimestamp: z.string().nullable().optional(),
  accountKey: z.string().nullable().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  lmsId: z.number(),
  language: z.string(),
  country: z.string(),
  active: z.boolean().nullable().optional(),
  avatar: z.string().nullable().optional(),
  branches: z
    .array(
      z.object({
        id: z.string(),
        url: z.string(),
        title: z.string(),
        isGlobal: z.boolean(),
        clientId: z.string().nullable().optional(),
        description: z.string(),
        country: z.string(),
        language: z.string(),
        isPreProduction: z.boolean(),
        courses: z.array(
          z.object({
            id: z.string(),
            name: z.string(),
          }),
        ),
      }),
    )
    .nullable()
    .optional(),
  accessRights: z.array(z.enum(ACCESS_RIGHTS)),
  profession: z.string().nullable().optional(),
  tasks: z
    .array(
      z.object({
        id: z.string(),
        title: z.string(),
        short: z.string(),
      }),
    )
    .nullable()
    .optional(),
  courses: z
    .array(
      z.object({
        id: z.string(),
        title: z.string(),
        clientId: z.string(),
        completionStatus: z.string(),
        completionPercentage: z.string(),
        enrolledOn: z.string().nullable().optional(),
        completedOn: z.string().nullable().optional(),
        expiredOn: z.number().nullable().optional(),
        totalTimeSeconds: z.number(),
        accessLink: z.string(),
      }),
    )
    .nullable()
    .optional(),
  seenWizardAt: z.string().nullable().optional(),
  center: z.string().nullable().optional(),
});

export type User = z.infer<typeof userSchema>;
