import { styled } from "styled-components";
import { Colors, Media } from "../../Contstants";

export const RegistrationHeadlineContainer = styled.div`
  text-align: center;
  padding: 0;
  margin-bottom: 32px;

  img {
    width: 55px;
  }

  h3 {
    color: ${Colors.brand};
    margin: 22px 0 16px 0;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & > p {
    margin: 0;
    color: #4e6276;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const RegistrationFormContainer = styled.div<{ $position: string }>`
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 25px 32px;
  justify-content: ${(props) => props.$position};

  @media (max-width: ${Media.mobile}) {
    width: 100%;
  }

  .full-width {
    width: 100%;
    margin-bottom: 30px;

    & > div {
      margin: 0;
    }
  }

  .submit {
    width: 100%;
    margin: 0px auto 32px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      min-height: 60px;
    }
    &.cursorChange {
      button {
        cursor: auto;
      }
    }
  }
`;

export const Layout = styled.div`
  display: flex;
  max-width: 1260px;
  margin: 5% auto;
  width: 100%;
  gap: 5%;

  .registration-form {
    width: 40%;
    padding: 2.5%;
    border-radius: 12px;
    border: 0.5px solid #878787;
    background: #fff;
    box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.05);

    @media (max-width: ${Media.mobile}) {
      width: 100%;
      border: 0;
      border-radius: 0;
      padding: 0;
    }
  }

  .intro {
    border-radius: 15px;
    background: ${Colors.brand};
    padding: 5% 4%;
    width: 57%;

    @media (max-width: ${Media.mobile}) {
      display: none;
    }

    h2 {
      color: #fff;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      margin: 0 0 8px 0;
    }

    p {
      margin: 0;
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  gap: 15px;

  &.dropdown {
    margin-bottom: 30px;
    margin-top: 5px;
  }

  & > div:nth-child(1),
  & > div:nth-child(2) {
    margin-right: 0 !important;
    width: 50%;
  }
`;
