import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography, Paper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from "recharts";

interface UsersData {
  [key: string]: number;
}

interface BarChartProps {
  title: string;
  fetchData: () => Promise<UsersData>;
}

const getColor = (index: number) => {
  const colors = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];
  return colors[index % colors.length];
};

const BarChartWidget: React.FC<BarChartProps> = ({ title, fetchData }) => {
  const [data, setData] = useState<UsersData>();

  const sortObjectByValues = (obj: UsersData) => {
    const keyValueArray = Object.entries(obj);
    keyValueArray.sort((a, b) => b[1] - a[1]);
    const sortedObject = Object.fromEntries(keyValueArray);
    return sortedObject;
  };

  useEffect(() => {
    const fetchDataAndSort = async () => {
      try {
        const responseData = await fetchData();
        if (responseData) {
          const sortedData = sortObjectByValues(responseData);
          setData(sortedData);
        }
      } catch (error) {
        alert(error);
      }
    };
    fetchDataAndSort();
  }, [fetchData]);

  return (
    <div className="bar-chart-widget">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper style={{ width: "100%", padding: "16px" }}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={data ? Object.entries(data).map(([label, value], index) => ({ label, value, fill: getColor(index) })) : []}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip labelFormatter={(value) => `${value}`} />
                <Legend />
                <Bar dataKey="value" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BarChartWidget;
