import { Link } from "react-router-dom";
import { styled } from "styled-components";
import Input from "../Input";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import { RegistrationFormContainer } from "./styled";
import { registrationCode, RegistrationDto } from "./utils";
import { useState, useMemo } from "react";
import countryList from "react-select-country-list";
import Checkbox from "../Checkbox";
import { useSaveLeadPhysicianMutation } from "../../hooks/mutations";

type Props = {
  setStep: (status: 0 | 1 | 2 | 3) => void;
  formData: RegistrationDto;
};

const LastRegistrationForm = ({ setStep, formData }: Props) => {
  const [termsCheckbox, setTermsCheckbox] = useState<boolean>(false);
  const [privacyCheckbox, setPrivacyCheckbox] = useState<boolean>(false);

  const mutation = useSaveLeadPhysicianMutation();

  const { t } = useTranslation();

  const countryFullName = useMemo(
    () =>
      countryList()
        .getData()
        .find((item) => item.value === formData?.country?.toUpperCase())?.label,
    [formData?.country],
  );

  const onValid = async () => {
    mutation.mutate(
      { ...formData, registrationCode, language: "en" },
      {
        onSuccess: () => setStep(3),
      },
    );
  };

  return (
    <RegistrationFormContainer $position="flex-start">
      <Header>One more step to complete registration!</Header>

      <div className="full-width">
        <Input
          label={t("registration.institution")}
          name="center"
          value={formData?.title}
          disabled
        />
      </div>

      <div className={`${styles.nameContainer} dropdown full-width`}>
        <div className={`${styles.countryInputsContainer} full-width`}>
          <Input
            name="country"
            label="Country"
            value={countryFullName}
            disabled
          />
        </div>
        <div className={`${styles.countryInputsContainer} full-width`}>
          <Input name="city" label="City" value={formData?.city} disabled />
        </div>
      </div>

      <CheckboxContainer $margin="0">
        <Checkbox
          label={
            <p>
              I agree to the{" "}
              <Link rel="noreferrer" target="_blank" to="/terms-of-use">
                Terms of Use
              </Link>
            </p>
          }
          onChange={(_, checked) => setTermsCheckbox(checked)}
        />
      </CheckboxContainer>

      <CheckboxContainer $margin="30px">
        <Checkbox
          label={
            <p>
              I agree to the{" "}
              <Link rel="noreferrer" target="_blank" to="/privacy-policy">
                Privacy Policy
              </Link>
            </p>
          }
          onChange={(_, checked) => setPrivacyCheckbox(checked)}
        />
      </CheckboxContainer>

      <ErrorMessage error={mutation.error} />

      <div className={`submit ${mutation.isLoading ? "cursorChange" : ""}`}>
        <Button
          isLoading={mutation.isLoading}
          disabled={mutation.isLoading || !termsCheckbox || !privacyCheckbox}
          fullWidth
          title={"Finish"}
          onClick={onValid}
        />
      </div>
    </RegistrationFormContainer>
  );
};

export default LastRegistrationForm;

const ErrorMessage = (props: { error: unknown }) => {
  if (!props.error) {
    return null;
  }

  let error: string | null = null;

  if (props.error instanceof Error) {
    error = props.error.message;
  } else if (typeof props.error === "string") {
    error = props.error;
  } else {
    error = "Something went wrong";
  }

  return <ErrorText>{error}</ErrorText>;
};

const CheckboxContainer = styled.div<{ $margin: string }>`
  display: flex;
  justify-items: flex-start;
  width: 100%;
  margin-bottom: ${(props) => props.$margin};
  p {
    color: #4e6276;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
  }

  a {
    color: #5080f4;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const Header = styled.h2`
  color: #142a5e;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
`;

const ErrorText = styled.span`
  color: #e65e6e;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
`;
