import { FC } from "react";
import Button from "../Button";
import { TabHeaderContainer } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
interface IProps {
  title: string;
  text: string;
  icon?: "notifaction" | "avatar" | "lock";
  updated?: boolean;
  save?: () => void;
  isLoading: boolean;
}

const TabHeader: FC<IProps> = ({
  title,
  text,
  icon,
  updated,
  save,
  isLoading,
}) => {
  const { t } = useTranslation();
  const renderIcon = () => {
    if (icon === "avatar") {
      return faUser;
    } else if (icon === "lock") {
      return faLock;
    } else {
      return faBell;
    }
  };

  return (
    <TabHeaderContainer>
      <div>
        <h3>
          {title}
          <FontAwesomeIcon icon={renderIcon()} />
        </h3>
        <p> {text} </p>
      </div>

      {title === "Password" ? (
        ""
      ) : (
        <div className="buttons">
          <Button
            onClick={() => {
              save && save();
            }}
            width="120px"
            green
            small
            disabled={!updated}
            isLoading={isLoading}
            title={t("settings.save_changes")}
          />
        </div>
      )}
    </TabHeaderContainer>
  );
};

export default TabHeader;
