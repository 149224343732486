import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en/translation.json";
import ae from "./ae/translation.json";

i18next.use(initReactI18next).init({
  fallbackLng: "en",
  resources: {
    en: { translation: en },
    ae: { translation: ae },
  },
  lng: "en",
  interpolation: { escapeValue: false },
});
