import { styled } from "styled-components";
import Check from "../../assets/images/check.png";

const SuccessMessage = () => {
  return (
    <Container>
      <SuccesImg src={Check} alt="success" />
      <Header>You have successfully registered - Welcome to One4DMD !</Header>
      <Text>
        We have sent you an email with instructions to set up your password.
      </Text>
    </Container>
  );
};

export default SuccessMessage;

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 43px 24px 116px 24px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const SuccesImg = styled.img`
  width: 55px;
  height: 55px;
  flex-shrink: 0;
`;

const Header = styled.h2`
  color: #142a5e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

const Text = styled.p`
  color: #4e6276;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
