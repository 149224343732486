import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
    short: z.string(),
  }),
);

type Response = z.infer<typeof schema>;

async function getPractitionerTasks() {
  const { data } = await axiosInstance.get("list/tasks");
  return schema.parse(data);
}

export function useGetPractitionerTasksQuery(
  options?: UseQueryOptions<Response>,
) {
  return useQuery<Response>("practitoner-tasks", getPractitionerTasks, options);
}
