import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    id: z.string(),
    value: z.string(),
  }),
);

type Response = z.infer<typeof schema>;

async function getCountries(address: string) {
  const { data } = await axiosInstance.get(address);
  return schema.parse(data);
}

export function useGetCountriesQuery(
  address: string,
  options?: UseQueryOptions<Response>,
) {
  return useQuery<Response>("countries", () => getCountries(address), options);
}
