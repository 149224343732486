import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

interface MutationArguments {
  seenWizardAt: boolean;
}

function mutationFn({ seenWizardAt }: MutationArguments) {
  return axiosInstance.patch("/account", {
    seenWizardAt,
  });
}

export function useToggleWizardVisibilityMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, MutationArguments>,
    "mutationFn"
  >,
) {
  return useMutation(mutationFn, options);
}
