import Layout from "../Layout";
// import Details from "./Details";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faLocationDot,
  faAt,
} from "@fortawesome/free-solid-svg-icons";
import { ContactCnt, ImgWrapper } from "./styled";
import LinkedIn from "../../assets/images/Linkedin";

const ContactPanel = () => {
  const handleEmailClick = () => {
    const subject = encodeURIComponent("Feedback from One4Educate");
    window.open(`mailto:support@dh-companion.com?subject=${subject}`);
  };
  return (
    <Layout>
      <ContactCnt>
        <div className="flexCnt">
          <ImgWrapper>
            <h1>Do you have questions or suggestions?</h1>
            <h3>We are there to help!</h3>
            <h2>Digital Health Companion GmbH (DHC)</h2>
            <div className="flex">
              <FontAwesomeIcon
                icon={faPhoneVolume}
                style={{ color: "#142a5e" }}
              />
              <div className="contactText">+49-176-61127433</div>
            </div>
            <div className="flex">
              <FontAwesomeIcon
                icon={faLocationDot}
                style={{ color: "#142a5e" }}
              />
              <div className="contactText">
                Alte Leimengrube 14 | 68526 Ladenburg | Germany
              </div>
            </div>

            <a
              href="https://www.linkedin.com/company/dhc-digital-health-companion/about/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex">
                <LinkedIn fill="#142a5e" />
                <div className="contactText">Linkedin</div>
              </div>
            </a>
            <div>
              <div
                className="flex"
                onClick={handleEmailClick}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faAt} style={{ color: "#142a5e" }} />
                <div className="contactText">support@dh-companion.com</div>
              </div>
            </div>
          </ImgWrapper>
        </div>
        {/* <Details /> */}
      </ContactCnt>
    </Layout>
  );
};

export default ContactPanel;
