import "./App.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/poppins";

import Routing from "./routing/Routing";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./services/scrollTop";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./queryClient";
import "./locales/lang";
import { Analytics } from "./services/countly.service";

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <Routing />
        <ToastContainer />
        <Analytics />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </div>
  );
}

export default App;
