// @ts-ignore
import Countly from "countly-sdk-web";
import axios from "axios";
import axiosInstance from "../utils/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const SERVER_BASE_URL = `${process.env.REACT_APP_API}/v1/countly`;

declare global {
  interface Window {
    Countly: any;
  }
}

window.Countly = Countly;

export { Countly };

export function Analytics() {
  const { pathname } = useLocation();

  useEffect(() => {
    initCountly();
  }, []);

  useEffect(() => {
    Countly.q.push(["track_pageview", pathname]);
  }, [pathname]);

  return null;
}

export async function initCountly() {
  const { data } = await axiosInstance.get("ip");

  Countly.init({
    app_key: process.env.REACT_APP_COUNTLY_APPKEY,
    url: process.env.REACT_APP_COUNTLY_URL,
    session_update: 10,
    use_session_cookie: true,
    debug: false,
    require_consent: true,
    namespace: "carisma-portal",
    inactivity_time: 1,
    offline_mode: false,
    use_explicit_rc_api: true,
    ip_address: data,
  });

  Countly.q.push(["track_sessions"]);
  Countly.q.push(["track_scrolls"]);
  Countly.q.push(["track_clicks"]);
  Countly.q.push(["track_links"]);
  Countly.q.push(["track_errors"]);
}

// increment number of sent feedback forms
export const pushSentFeedbackEventToCountly = () => {
  Countly.q.push([
    "add_event",
    {
      key: "sentFeedback",
      count: 1,
    },
  ]);
};

// increment page visit count and time spent in minutes
export const pushCustomPageViewEventToCountly = (
  startTime: Date,
  pagePath: string,
) => {
  return () => {
    const endTime = new Date();
    const elapsedTime: any = endTime.getTime() - startTime.getTime();
    const elapsedSeconds = Math.floor(elapsedTime / 1000);
    Countly.q.push([
      "add_event",
      {
        key: "customPageViews",
        count: 1,
        sum: elapsedSeconds,
        // timeSpentInMinutes: elapsedSeconds,
        segmentation: {
          page: pagePath,
        },
      },
    ]);
  };
};

export function setCountlyDeviceId(userId: string) {
  Countly.change_id(userId);
}

export const getSentFeedbacksCount = async () => {
  const requestUrl = SERVER_BASE_URL + "/feedbacks";
  const data = (await axios.get(requestUrl)).data;
  return data;
};

export const getTotalUsersCount = async () => {
  const requestUrl = SERVER_BASE_URL + "/users-total";
  const data = (await axios.get(requestUrl)).data;
  return data;
};

export const getPageViewsAndTimeSpent = async () => {
  const requestUrl = SERVER_BASE_URL + "/pageviews";
  const data = (await axios.get(requestUrl)).data;
  return data;
};

export const checkIfUserDataAlreadyStoredInCountly = async (userId: string) => {
  const requestUrl = SERVER_BASE_URL + `/check-user-exists/${userId}`;
  const doesUserExist = (await axios.get(requestUrl)).data;
  return doesUserExist;
};

export const getUsersPerRole = async () => {
  const requestUrl = SERVER_BASE_URL + `/segmentation-count/role`;
  const usersPerRole = (await axios.get(requestUrl)).data;
  return usersPerRole;
};

export const getUsersPerCountry = async () => {
  const requestUrl = SERVER_BASE_URL + `/segmentation-count/country`;
  const usersPerCountry = (await axios.get(requestUrl)).data;
  return usersPerCountry;
};

export const getUsersPerCity = async () => {
  const requestUrl = SERVER_BASE_URL + `/segmentation-count/city`;
  const usersPerCity = (await axios.get(requestUrl)).data;
  return usersPerCity;
};

export const getUsersPerPostalCode = async () => {
  const requestUrl = SERVER_BASE_URL + `/segmentation-count/postCode`;
  const usersPerPostalCode = (await axios.get(requestUrl)).data;
  return usersPerPostalCode;
};

export const getUsersPerLanguage = async () => {
  const requestUrl = SERVER_BASE_URL + `/segmentation-count/language`;
  const usersPerLanguage = (await axios.get(requestUrl)).data;
  return usersPerLanguage;
};

export const getUsersPerCenter = async () => {
  const requestUrl = SERVER_BASE_URL + `/segmentation-count/center`;
  const usersPerCenter = (await axios.get(requestUrl)).data;
  return usersPerCenter;
};

export const getMonthlyDailyActiveUsers = async () => {
  const requestUrl = SERVER_BASE_URL + `/sessions`;
  const activeUsersPerThisMonthAndDay = (await axios.get(requestUrl)).data;
  return activeUsersPerThisMonthAndDay;
};

export const getBounceRates = async () => {
  const requestUrl = SERVER_BASE_URL + `/bounces`;
  const bounceRatesData = (await axios.get(requestUrl)).data;
  return bounceRatesData;
};

export const getAcquisitionData = async () => {
  const requestUrl = SERVER_BASE_URL + `/acquisition`;
  const acquisitionData = (await axios.get(requestUrl)).data;
  return acquisitionData;
};

// push user info to countly server
// export const pushCustomUserInfoEventToCountly = async () => {
//   const {
//     userData: { center, city, language, postCode, userRole, country, userId },
//   } = useCountlyStore.getState();

//   const userExist = await checkIfUserDataAlreadyStoredInCountly(userId);
//   if (userExist) {
//     return;
//   }

//   Countly.q.push([
//     "add_event",
//     {
//       key: "customUserInfo",
//       segmentation: {
//         userId: userId,
//         center: center,
//         city: city,
//         country: country,
//         postCode: postCode,
//         language: language,
//         role: userRole,
//       },
//     },
//   ]);
// };
