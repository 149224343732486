import Dashboard from "../pages/Dashboard";
import Registration from "../pages/Registration";
import Active from "../pages/Activate/index";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Contact from "../pages/Contact";
import Impressum from "../pages/Impressum";
import Profile from "../pages/Profile";
import CountlyAnalytics from "../pages/CountlyAnalytics";
import { ProtectedRoutesWrapper } from "./utils";
import Login from "../pages/Login";
import TermsOfUse from "../pages/TermsOfUse";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutesWrapper />}>
        <Route index element={<Dashboard />} />
        <Route path="lms-analytics" element={<CountlyAnalytics />} />
        <Route path="profile" element={<Profile />} />
        <Route path="login" element={<Login />} />
      </Route>

      <Route path="contact" element={<Contact />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/imprint" element={<Impressum />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/activate" element={<Active />} />
    </Routes>
  );
};

export default Routing;
