import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./PageViewsWidget.css";

export interface PageData {
  page: string;
  visits: {
    currentYear: number;
    currentMonth: number;
    today: number;
  };
  timeSpentInSeconds: {
    totals: {
      currentYear: number;
      currentMonth: number;
      today: number;
    };
    average: {
      currentYear: string;
      currentMonth: string;
      today: string;
    };
  };
}

interface Props {
  data: { [key: string]: PageData };
}

const textStrings = {
  mostVisitedPagesTableHeading: "Most visited pages",
  mostInterestingPagesTableHeading: "Most interesting pages",
  page: "Page",
  today: "Today",
  average: "Avg",
  showTotals: "Show Totals",
  showAverages: "Show Averages",
  loadingPageViews: "Loading page analytics...",
};

function getCurrentDateStrings(): {
  year: string;
  month: string;
  day: string;
  monthName: string;
} {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const currentMonth = (currentDate.getMonth() + 1).toString();
  const currentDay = currentDate.getDate().toString();

  const monthName = new Date().toLocaleString("en-us", {
    month: "short",
    year: "numeric",
  });
  return { year: currentYear, month: currentMonth, day: currentDay, monthName };
}

const PageViewsWidget: React.FC<Props> = ({ data }) => {
  const { year, monthName } = getCurrentDateStrings();
  const [pagesVisitData, setPagesVisitData] = useState<{
    [key: string]: PageData;
  }>(data);
  const [pagesTimeSpentData, setPagesTimeSpentData] = useState<{
    [key: string]: PageData;
  }>(data);
  const [showTimeSpentAverages, setShowTimeSpentAverages] =
    useState<boolean>(true);

  useEffect(() => {
    sortPagestByVisits(data);
    sortPagesByTimeSpent(data);
  }, [data]);

  const sortPagestByVisits = (
    data: { [key: string]: PageData },
    sortBy: string = "currentYear"
  ) => {
    const pages = Object.keys(data);

    const sortedPages = pages.sort((page1, page2) => {
      if (sortBy === "currentYear") {
        return data[page2].visits.currentYear - data[page1].visits.currentYear;
      } else if (sortBy === "currentMonth") {
        return (
          data[page2].visits.currentMonth - data[page1].visits.currentMonth
        );
      } else if (sortBy === "today") {
        return data[page2].visits.today - data[page1].visits.today;
      } else {
        return 0;
      }
    });

    const sortedData: { [key: string]: PageData } = {};

    sortedPages.forEach((page) => {
      sortedData[page] = data[page];
    });

    setPagesVisitData(sortedData);
  };

  const sortPagesByTimeSpent = (
    data: { [key: string]: PageData },
    sortBy: string = "currentMonth",
    average: boolean | string = false
  ) => {
    const pages = Object.keys(data);

    const sortedPages = pages.sort((page1: string, page2: string) => {
      if (!average) {
        if (sortBy === "currentYear") {
          return (
            data[page2].timeSpentInSeconds.totals.currentYear -
            data[page1].timeSpentInSeconds.totals.currentYear
          );
        } else if (sortBy === "currentMonth") {
          return (
            data[page2].timeSpentInSeconds.totals.currentMonth -
            data[page1].timeSpentInSeconds.totals.currentMonth
          );
        } else if (sortBy === "today") {
          return (
            data[page2].timeSpentInSeconds.totals.today -
            data[page1].timeSpentInSeconds.totals.today
          );
        }
      }

      if (average) {
        if (sortBy === "currentYear") {
          return (
            Number(data[page2].timeSpentInSeconds.average.currentYear) -
            Number(data[page1].timeSpentInSeconds.average.currentYear)
          );
        } else if (sortBy === "currentMonth") {
          return (
            Number(data[page2].timeSpentInSeconds.average.currentMonth) -
            Number(data[page1].timeSpentInSeconds.average.currentMonth)
          );
        } else if (sortBy === "today") {
          return (
            Number(data[page2].timeSpentInSeconds.average.today) -
            Number(data[page1].timeSpentInSeconds.average.today)
          );
        }
      }

      return 0;
    });

    const sortedData: { [key: string]: PageData } = {};

    sortedPages.forEach((page: string) => {
      sortedData[page] = data[page];
    });

    setPagesTimeSpentData(sortedData);
  };

  return (
    <div className="page-views-widget">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h5>{textStrings.mostVisitedPagesTableHeading}</h5>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table className="page-views-time-table">
              <TableHead>
                <TableRow className="table-heading-row">
                  <TableCell className="table-heading">
                    {textStrings.page}
                  </TableCell>
                  <TableCell className="table-heading">
                    <Button onClick={() => sortPagestByVisits(data)}>
                      {year}
                    </Button>
                  </TableCell>
                  <TableCell className="table-heading">
                    <Button
                      onClick={() => sortPagestByVisits(data, "currentMonth")}
                    >
                      {monthName}
                    </Button>
                  </TableCell>
                  <TableCell className="table-heading">
                    <Button onClick={() => sortPagestByVisits(data, "today")}>
                      {textStrings.today}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(pagesVisitData).map((page: string) => {
                  const pageData = data[page];
                  const visits = pageData.visits;
                  return (
                    <TableRow key={page}>
                      <TableCell className="border text-left">{page}</TableCell>
                      <TableCell className="border text-left">
                        {visits.currentYear}
                      </TableCell>
                      <TableCell className="border text-left">
                        {visits.currentMonth}
                      </TableCell>
                      <TableCell className="border text-left">
                        {visits.today}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h5>{textStrings.mostInterestingPagesTableHeading}</h5>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table className="page-views-time-table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Button
                      onClick={() =>
                        setShowTimeSpentAverages(!showTimeSpentAverages)
                      }
                    >
                      {showTimeSpentAverages
                        ? textStrings.showTotals
                        : textStrings.showAverages}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="table-heading">
                    {textStrings.page}
                  </TableCell>
                  {!showTimeSpentAverages && (
                    <>
                      <TableCell className="table-heading">
                        <Button
                          onClick={() =>
                            sortPagesByTimeSpent(data, "currentYear")
                          }
                        >
                          {year}
                        </Button>
                      </TableCell>
                      <TableCell className="table-heading">
                        <Button
                          onClick={() =>
                            sortPagesByTimeSpent(data, "currentMonth")
                          }
                        >
                          {monthName}
                        </Button>
                      </TableCell>
                      <TableCell className="table-heading">
                        <Button
                          onClick={() => sortPagesByTimeSpent(data, "today")}
                        >
                          {textStrings.today}
                        </Button>
                      </TableCell>
                    </>
                  )}
                  {showTimeSpentAverages && (
                    <>
                      <TableCell className="table-heading">
                        <Button
                          onClick={() =>
                            sortPagesByTimeSpent(data, "currentYear", "average")
                          }
                        >
                          {textStrings.average} {year}
                        </Button>
                      </TableCell>
                      <TableCell className="table-heading">
                        <Button
                          onClick={() =>
                            sortPagesByTimeSpent(
                              data,
                              "currentMonth",
                              "average"
                            )
                          }
                        >
                          {textStrings.average} {monthName}
                        </Button>
                      </TableCell>
                      <TableCell className="table-heading">
                        <Button
                          onClick={() =>
                            sortPagesByTimeSpent(data, "today", "average")
                          }
                        >
                          {textStrings.average} {textStrings.today}
                        </Button>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(pagesTimeSpentData).map((page: string) => {
                  const pageData = data[page];
                  const timeSpent = pageData.timeSpentInSeconds;
                  return (
                    <TableRow key={page} className="page-view-time-row">
                      <TableCell className="border text-left">{page}</TableCell>
                      {!showTimeSpentAverages && (
                        <>
                          <TableCell className="border text-left">
                            {timeSpent.totals.currentYear}
                          </TableCell>
                          <TableCell className="border text-left">
                            {timeSpent.totals.currentMonth}
                          </TableCell>
                          <TableCell className="border text-left">
                            {timeSpent.totals.today}
                          </TableCell>
                        </>
                      )}
                      {showTimeSpentAverages && (
                        <>
                          <TableCell className="border text-left">
                            {timeSpent.average.currentYear}
                          </TableCell>
                          <TableCell className="border text-left">
                            {timeSpent.average.currentMonth}
                          </TableCell>
                          <TableCell className="border text-left">
                            {timeSpent.average.today}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PageViewsWidget;
