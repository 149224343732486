import { useRef, MouseEvent } from "react";
import { styled } from "styled-components";
import keycloakInstance from "../../../../Keycloak";
import { useDeactivateSectionContext } from "../../ExpertSettings";

const SuccessText = () => {
  const { setDisableStatus } = useDeactivateSectionContext();

  const containerRef = useRef<HTMLDivElement>(null);

  const MainContainerHandler = (e: MouseEvent<HTMLElement>) => {
    if (containerRef.current === e.target) {
      setDisableStatus(false);
    }
  };
  const CancelButtonHandler = () => {
    const one4DmdUrl = process.env.REACT_APP_ONE4DMD_URL;
    const url = keycloakInstance.createLogoutUrl({
      redirectUri: one4DmdUrl,
    });
    window.location.href = url;
  };
  return (
    <MainContainer ref={containerRef} onClick={(e) => MainContainerHandler(e)}>
      <ModalSection>
        <Container>
          <h2>Account Deactivation Successful</h2>

          <p>
            Your account deactivation request has been processed successfully.
            We appreciate the time you've
          </p>
          <p>
            spent with us and value your feedback. If you ever decide to return,
            we'll be here to welcome you back.
          </p>
          <p>Thank you for being a part of our community!</p>
        </Container>
        <CancelButton onClick={CancelButtonHandler}>Close</CancelButton>
      </ModalSection>
    </MainContainer>
  );
};

export default SuccessText;

const Container = styled.div`
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  p {
    color: #1a2127;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
  }
  h2 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3a9cad;
    align-self: center;
  }
`;

const MainContainer = styled.div`
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalSection = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 20px 0px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
`;

const CancelButton = styled.button`
  background-color: #ffffff;
  display: flex;
  width: 136px;
  height: 45px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #3a9cad;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #d0d5dd;
`;
