import { UseQueryOptions, useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { z } from "zod";

const schema = z.array(
  z.object({
    accessLink: z.string(),
    avatar: z.string(),
    branchId: z.string(),
    clientId: z.string(),
    completedOn: z.string().nullable(),
    completionPercentage: z.string(),
    completionStatus: z.string(),
    enrolledOn: z.string(),
    expiredOn: z.string().nullable(),
    id: z.string(),
    title: z.string(),
  }),
);

export type AllCoursesResponse = z.infer<typeof schema>;

function getAllCourses() {
  return axiosInstance.get("account/courses").then((res) => res.data);
}

export function useGetAllCoursesQuery(
  options?: UseQueryOptions<AllCoursesResponse>,
) {
  return useQuery<AllCoursesResponse>("allCourses", getAllCourses, options);
}
