import styled from "styled-components";
import { Colors } from "../../Contstants";

interface Props {
  footer?: boolean;
}

export const Header = styled.h1<Props>`
  color: ${Colors.brand};
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;
export const Wrapper = styled.div<Props>`
  margin: 0 20px;
`;

export const SubHeader = styled.h1<Props>`
  color: ${Colors.brand};
  font-size: 16px;
  font-weight: 700;
`;

export const TextCnt = styled.div<Props>`
  color: ${Colors.textDark};
  padding-bottom: 30px;

  h3 {
    color: ${Colors.brand};
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 0;
    &.copyRight {
      color: #4e6276;
    }
  }

  .subHeader {
    margin-bottom: 30px;
  }

  ol,
  p,
  ul {
    margin: 0;
  }

  ol,
  ul {
    padding-left: 25px;
  }
  p a {
    text-decoration: underline;
  }
`;
export const Text = styled.div<Props>``;
