import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";
import { AxiosResponse } from "axios";
import { RegistrationDto } from "../../components/Registration/utils";

interface MutationArguments {
  email: string;
}

function mutationFn(
  data: MutationArguments,
): Promise<AxiosResponse<RegistrationDto>> {
  const code = process.env.REACT_APP_REGISTRATION_CODE_ONE4DMD;

  return axiosInstance.post(`register/precheck?registrationCode=${code}`, {
    email: data.email,
  });
}

export function useCheckEmailMutation(
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<RegistrationDto>,
      unknown,
      MutationArguments
    >,
    "mutationFn"
  >,
) {
  return useMutation(mutationFn, options);
}
