import styles from "./styles.module.css";
import Lock from "../../../../assets/images/lock.svg";
import RegistrationInput from "../RegistrationInput";
import Button from "../../../../components/Button";
import { useRef } from "react";

type Props = {
  onActivate: () => void;
};

const registrationCode = process.env.REACT_APP_REGISTRATION_CODE_ONE4DMD;

const RegistrationCodePrompt = ({ onActivate }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (inputRef.current?.value === registrationCode) {
      onActivate();
    }
  };

  return (
    <div className={styles.container}>
      <header>
        <h3 className={styles.title}>Activate your account</h3>
        <h5 className={styles.subtitle}>
          Get started here if you have received a registration code 
        </h5>
      </header>

      <section>
        <RegistrationInput
          ref={inputRef}
          placeholder="Registration code"
          name="register"
          icon={<img src={Lock} alt="lock" />}
        />
      </section>

      <footer>
        <Button
          onClick={onClick}
          title={"Activate now"}
          fullWidth
          fontFamily={"Poppins"}
          fontWeight={500}
        />
        <p className={styles.alreadyText}>
          Already have an account?{" "}
          <a href="/login" className={styles.signInText}>
            Sign in here
          </a>
        </p>
      </footer>
    </div>
  );
};

export default RegistrationCodePrompt;
