import { RegistrationHeadlineContainer } from "./styled";

const RegistrationHeadline = () => {
  return (
    <RegistrationHeadlineContainer>
      <h3>You have successfully activated the registration process! </h3>
      <p>Please provide your account information to get started </p>
    </RegistrationHeadlineContainer>
  );
};

export default RegistrationHeadline;
