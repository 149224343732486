import { UseQueryOptions, useQuery } from "react-query";
import { User, userSchema } from "./utils";
import axiosInstance from "../../utils/axios";
import { Countly, setCountlyDeviceId } from "../../services/countly.service";

async function getUser(): Promise<User> {
  return axiosInstance
    .get("/account")
    .then(({ data }) => userSchema.parse(data));
}

function getUserOrganization(email: string) {
  switch (email.split("@")[1]) {
    case "dh-companion.com":
      return "DHC";
    case "roche.com":
      return "Roche";
    case "novartis.com":
      return "Novartis";
    case "pfizer.com":
      return "Pfizer";
    default:
      return "Unknown";
  }
}

export const useUser = (options?: UseQueryOptions<User>) => {
  const { data, ...rest } = useQuery<User>(["User"], getUser, {
    ...options,
    onSuccess: (data) => {
      setCountlyDeviceId(data.id);

      Countly.q.push([
        "user_details",
        {
          name: data.name + " " + data.surname,
          username: data.name + " " + data.surname,
          email: data.email,
          organization: getUserOrganization(data.email),
          picture: data.avatar,
        },
      ]);

      options?.onSuccess?.(data);
    },
  });

  return {
    user: data,
    ...rest,
  };
};
